import React from "react";
import {withRouter} from "react-router";
import {SD_Logo_circle} from "../../Assets/imageList";
const LoadingContent = ({history}) => {
  return (
    <div
      style={{
        textAlignLast: "center",
        padding: "5vw",
      }}
    >
      <img
        src={SD_Logo_circle}
        alt="logo"
        height="100px"
        style={{
          border: "2px solid orange",
          borderRadius: "50%",
        }}
      />
      <br />

      <p style={{marginTop: "10px"}}> Coming Soon</p>
      <br />
      <p style={{color: "#181818"}}>
        Return to{" "}
        <a
          style={{color: "#2f3193", fontWeight: "600", cursor: "pointer"}}
          onClick={() => history?.push(`/`)}
        >
          Home page
        </a>
      </p>
    </div>
  );
};
export default withRouter(LoadingContent);
