/* eslint-disable react/jsx-pascal-case */
import React, { useContext } from "react";
import { Switch, Route, withRouter, Redirect } from "react-router-dom";
import { SD_Context } from "./context/sd-context";
import New_Nav from "./Components/sd-navbar/sd-new-nav";
import Footer from "./Components/sd-footer/sd-footer";
import Sd_Routes from "./sd-routes";
import UserService from "./services/sd-user.service";
import Sd_error_page from "./pages/sd-error-pages/sd-error-response";

import ErrorBoundary from "./context/sd-error-boundary";

const App = React.memo(() => {
  const { setUserData, refContainer } = useContext(SD_Context);
  const supportedLanguages = ["en-in", "jp-in", "de-in", "es-in"];
  const templeID = process.env.REACT_APP_TEMPLE ?? "SDMSD";
  const [loading, setLoading] = React.useState(true);
  // const [trig, setTrig] = useState(false);
  // const [selectedLanguage, setSelectedlanguage] = useState("");
  // const [shift, setShift] = useState(false);

  React.useEffect(async () => {
    // if (
    //   supportedLanguages.includes(
    //     props.history?.location?.pathname?.split?.("/")?.[1]
    //   )
    // ) {
    //   setSelectedlanguage(props.history?.location?.pathname?.split?.("/")?.[1]);
    // } else {
    //   setSelectedlanguage("en-in");
    // }

    if (localStorage.getItem("accessToken")) {
      checkToken();
    } else {
      setLoading(false);
      // setShift(false);
    }
  }, []);

  const checkToken = async () => {
    try {
      setLoading(true);
      if (localStorage.getItem("accessToken")) {
        // console.log("in access token");
        let data = await UserService.checkToken(templeID);
        setUserData(data);
        // setShift(true);
      }
    } catch (error) {
      // console.log(error);
      localStorage.clear();
    }
    // setShift(true);

    setLoading(false);
  };
  // console.log("app");
  // const closeFunction = () => {
  //   setTimeout(() => {
  //     setTrig(true);
  //     try {
  //       refe.current.focus();
  //     } catch (e) {
  //       // console.log("");
  //     }
  //   }, 3000);
  //   setTimeout(() => {
  //     if (display) {
  //       setDisplay(false);
  //     }
  //   }, 10000);
  // };

  return (
    <>
      {!loading && (
        <div className="sd-main" id="myDIV" name="myDIV">
          <New_Nav />
          <div ref={refContainer}></div>
          <ErrorBoundary>
            <Switch>
              <Route
                path="/:lang"
                render={(props) => {
                  if (
                    props?.history?.location?.pathname?.replaceAll?.(
                      "/",
                      ""
                    ) === props?.match?.params?.lang &&
                    supportedLanguages.includes(props?.match?.params?.lang)
                  ) {
                    return (
                      <Redirect to={`/${props?.match?.params?.lang}/home`} />
                    );
                  }
                  return supportedLanguages.includes(
                    props?.match?.params?.lang
                  ) ? (
                    <Sd_Routes {...props} />
                  ) : (
                    <Redirect
                      to={`/en-in${props?.history?.location?.pathname}`}
                    />
                  );
                }}
              />
              <Route
                path="*"
                render={(props) => {
                  // console.log("%c euhduiheruiuivheurv");
                  return (
                    <Redirect
                      to={`/en-in${props?.history?.location?.pathname}`}
                    />
                  );
                }}
              />
            </Switch>
            <Footer />
          </ErrorBoundary>
        </div>
      )}
      <div className="sd-error">
        <Sd_error_page />
      </div>
    </>
  );
});
App.displayName = "Apps";

export default withRouter(App);
