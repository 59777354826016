import React from "react";
import {
  // ganapathi,
  // homam,
  yellow_arrow,
  SD_divider,
  // seshavaham,
  template5,
} from "../../../Assets/imageList";
import "../../../Utils/sd-styles/sd-main-sup.scss";
// import YoutubeService from "../../../services/sd-youtube.service";

const TemplateFive = () => {
  //-------------------------------------------------------------states--------------------------------------------------------//

  // const [isYoutubeLive, setIsYoutubeLive] = useState(true);
  //-------------------------------------------------------------states--------------------------------------------------------//

  //-------------------------------------------------------------useEffects--------------------------------------------------------//

  //-------------------------------------------------------------useEffects--------------------------------------------------------//

  return (
    <div className="sd-template-six ">
      <div className="sd-t6-one">
        <img src={template5} alt="alt" />
      </div>

      <div className="sd-t6-two">
        <div>
          <h1>About Temple</h1>
          <img src={SD_divider} alt="-" style={{ height: "20px" }}></img>
          <p style={{ marginTop: "0" }}>
            Sri Kalahastheeswara Swami Vari Temple is one of the most ancient
            and historical Saivet temple in India. Vayu is incarnated as Lord
            Siva and worshipped as Kalahastheeswara.
          </p>
          <h5>
            Goddess Parvathi Devi is worshiped here as Gnanaprasunambika. The
            temple is located at Sri kalahasti, Chittoor District Andhra
            Pradesh. The vast west facing Kalahastheeswara temple is built
            adjoining a hill, and on the banks of the river Swarnamukhi. At some
            points, the hill serves as the wall of the temple. The temple
            prakarams follow the contour of the adjoining hill and hence the
            temple plan is rather irregular. North of the temple is the
            Durgambika hill, south is the Kannappar hill and east is the
            Kumaraswamy hill.
          </h5>
          <h5>
            Rahu-ketu Pooja is very much famous in Sri kalahasti. Rahu ketu
            Pooja will be performed during Rahu kalam timings. Devotees should
            be plan accordingly, so that they will available during Rahu kalam
            time at the temple. Sri Kalahasti Temple is always crowded with Rahu
            ketu Pooja, with Devotees coming from different parts of the
            Country.
          </h5>
          <div className="temples">
            {" "}
            <div>
              <img src={yellow_arrow} height="20px" />
              The Temple
            </div>
            <div>
              <img src={yellow_arrow} height="20px" />
              The History
            </div>
            <div>
              <img src={yellow_arrow} height="20px" />
              General Information
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default TemplateFive;
