/* eslint-disable react/jsx-pascal-case */
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "../node_modules/@popperjs/core/dist/umd/popper.min.js";
import "../src/Utils/sd-styles/sd-css/slick.min.css";
import "../src/Utils/sd-styles/sd-css/slick.theme.min.css";
import "../src/Utils/sd-styles/sd-css/animate.min.css";
import "bootstrap/dist/css/bootstrap.min.css";

// import "../src/Utils/sd-styles/sd-bootstrap/js/bootstrap.min";
// import "../src/Utils/sd-styles/sd-bootstrap/css/bootstrap.min.css";
import SD_Context_Provider from "./context/sd-context";
import {BrowserRouter as Router} from "react-router-dom";

ReactDOM.render(
  <Router>
    <React.StrictMode>
      <SD_Context_Provider>
        <App />
      </SD_Context_Provider>
    </React.StrictMode>
  </Router>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
