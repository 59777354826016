// import {FALSE} from "node-sass";
import React from "react";
import { withRouter } from "react-router-dom";
import {
  SD_cart_icon,
  SD_Dropdown_Arrow_icon,
  SD_facebook_icon,
  SD_instagram_icon,
  SD_Logo_circle,
  sd_minus_icon,
  sd_plus_icon,
  SD_Print_a_ticket_icon,
  SD_Srisaila_TV_icon,
  SD_twitter_icon,
  SD_youtube_icon,
} from "../../Assets/imageList";
import { SD_Context } from "../../context/sd-context";
import NavBarData from "../../Utils/data/navbar.data";
const Sd_mobile_left_sider = ({
  setSider,
  selectedLanguage,
  setDropTwo,
  drop2,
  setDropOne,
  drop1,
  history,
  drop3,
  drop4,
  drop7,
  setDropSeven,
  drop5,
  setDropFive,
  setDropThree,
  setDropFour,
  drop6,
  setDropSix,
  drop8,
  drop9,
  drop10,
  setDropTen,
  setDropNine,
  setDropEight,
  drop11,
  drop12,
  setDrop11,
  setDrop12,
  drop13,
  setDrop13,
  drop14,
  setDrop14,
  drop15,
  setDrop15,
}) => {
  const { language, setlanguage } = React.useContext(SD_Context);
  //-------------------------------------------------------------funtion-----------------------------------------------------------//
  const Route_push = () => {
    setDropOne(false);
    setDropTwo(false);
    setDrop11(false);
    setDrop13(false);
    setDrop12(false);
    setDropFive(false);
    setDropThree(false);
    setDropSix(false);
    setDropFour(false);
    setDropTen(false);
    setDropNine(false);
    setDropEight(false);
    setDropSeven(false);
    setDrop14(false);
    setDrop15(false);
    setSider(false);
  };
  // const praytakshaSevaNav = [
  //   {
  //     key: " Suprabhata Seva (1-Person) ",
  //     path: " suprabhata-seva",
  //   },
  //   { key: " Sri Ammavari Vastram Seva ", path: " sri-ammavari-vastram-seva " },
  //   { key: " Kadgamalarchana ", path: " kadgamalarchana " },
  //   { key: " Ganapathi Homam ", path: " ganapathi-homam " },
  //   { key: " Rudra Homam ", path: " rudra-homam " },
  //   { key: " Navagraha Santhi Homam ", path: " navagraha-santhi-homam " },
  //   { key: " Lakshakumkumarchana ", path: " lakshakumkumarchana " },
  //   { key: " Sri Chakranavavaranachana ", path: " sri-chakranavavaranachana " },
  //   { key: " Mrutyunjaya Homam ", path: " mrutyunjaya-homam " },
  //   { key: " Chandi Homam ", path: " chandi-homam " },
  //   { key: " Santhi Kalyanam ", path: " santhi-kalyanam " },
  //   { key: " Rahu Ketu ", path: " rahu-ketu " },
  //   {
  //     key: " Pancha Harathulu(1-Person) ",
  //     path: " pancha-harathulu",
  //   },
  //   { key: " Pallaki Seva ", path: " pallaki-seva " },
  //   { key: " Pavalimpu Seva ", path: " pavalimpu-seva " },
  //   { key: " Astotanamarchana ", path: " astotanamarchana " },
  //   { key: " Sahasranamarchana ", path: " sahasranamarchana " },
  //   { key: " Annaprasana ", path: " annaprasana " },
  //   { key: " Aksharabhyasam ", path: " aksharabhyasam " },
  //   { key: " Sooryopasana(Sunday) ", path: " sooryopasana" },
  //   {
  //     key: " Subramanya Swami Kalyanam (Shashti) ",
  //     path: " subramanya-swami-kalyanam",
  //   },
  //   {
  //     key: (
  //       <>
  //         Sri Malleshwara Swami Vari
  //         <br />
  //         Panchamrutaabhishekam
  //       </>
  //     ),
  //     path: " sri-malleshwara-swami-vari-panchamrutaabhishekam ",
  //   },
  //   { key: " Veda Aasheeravachanam ", path: " veda-aasheeravachanam " },
  //   {
  //     key: (
  //       <>
  //         Sri Malleshwara Swami Vari
  //         <br /> Astotanamarchana
  //       </>
  //     ),
  //     path: " sri-malleshwara-swami-vari-astotanamarchana ",
  //   },
  // ];
  // const saswathaSevaNav = [
  //   { key: <>Sri Ammavari Vastram Seva </>, path: "sri-ammavari-vastram-seva" },
  //   {
  //     key: <>Khadgamala Pooja in Antharalayam </>,
  //     path: "khadgamala-pooja-in-antharalayam",
  //   },
  //   {
  //     key: (
  //       <>
  //         Ammavari Sahasranamarchana <br />
  //         (One Day){" "}
  //       </>
  //     ),
  //     path: "ammavari-sahasranamarchana",
  //   },
  //   {
  //     key: <>Ammavari Astotranamarchana (One Day) </>,
  //     path: "ammavari-astotranamarchana",
  //   },
  // ];
  //-------------------------------------------------------------funtion-----------------------------------------------------------//
  return (
    <div className="inner">
      <div className="inner-nav-top">
        <div className="left">
          <img
            className="logo"
            src={SD_Logo_circle}
            alt="icon"
            onClick={() => {
              Route_push();
              history?.push(`/${selectedLanguage}/home`);
            }}
          />
        </div>
        <div className="right">
          <div className="dropdown">
            <button
              className="btn"
              type="button"
              id="dropdownMenuButton1"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {language.desc} <img src={SD_Dropdown_Arrow_icon} alt="icon" />
            </button>
            <div
              className="dropdown-menu"
              aria-labelledby="dropdownMenuButton1"
            >
              <a
                className="dropdown-item"
                onClick={() => {
                  setlanguage("ENGLISH");
                }}
              >
                ENGLISH
              </a>
              <a
                className="dropdown-item"
                onClick={() => {
                  // setlanguage("TELUGU");
                }}
              >
                TELUGU
              </a>
              <a
                className="dropdown-item"
                onClick={() => {
                  // setlanguage("TAMIL");
                }}
              >
                TAMIL
              </a>
              <a
                className="dropdown-item"
                onClick={() => {
                  // setlanguage("KANNADA");
                }}
              >
                KANNADA
              </a>
              <a
                className="dropdown-item"
                onClick={() => {
                  // setlanguage("MARATHI");
                }}
              >
                MARATHI
              </a>
              <a
                className="dropdown-item"
                onClick={() => {
                  // setlanguage("HINDI");
                }}
              >
                HINDI
              </a>
            </div>
          </div>

          <img src={SD_cart_icon} alt={SD_cart_icon} />
          <button
            type="button"
            onClick={() => {
              setSider(false);
            }}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>

      <div className="inner-bottom">
        <div className="inner-bottom-t">
          <div
            onClick={() => {
              Route_push();
              window.open(
                "https://www.youtube.com/channel/UCNIzL2dT2gZxQae2LHXvl0A",
                "_blank"
              );
            }}
          >
            <img src={SD_Srisaila_TV_icon} alt={SD_Srisaila_TV_icon} />
            SRI KANIPAKA TV
          </div>
          <div
            onClick={() => {
              Route_push();
              history?.push(`/${selectedLanguage}/online-booking`);
            }}
          >
            <img src={SD_Print_a_ticket_icon} alt={SD_Print_a_ticket_icon} />
            PRINT A TICKET
          </div>
          <div
            onClick={() => {
              Route_push();
              history?.push(`/${selectedLanguage}/volunteer`);
            }}
          >
            VOLUNTEER
          </div>
          <div
            onClick={() => {
              Route_push();
              history?.push(`/${selectedLanguage}/shop`);
            }}
          >
            SHOP
          </div>
        </div>
        <div className="inner-bottom-b">
          <div
            className="between"
            onClick={() => {
              setDropThree(!drop3);
            }}
          >
            About
            {drop3 ? (
              <img
                src={sd_minus_icon}
                alt="-"
                onClick={() => {
                  setDropThree(!drop3);
                }}
              ></img>
            ) : (
              <img
                src={sd_plus_icon}
                alt="+"
                onClick={() => {
                  setDropThree(!drop3);
                }}
              />
            )}
          </div>

          <div className={drop3 ? "drop-three block" : "drop-three none"}>
            <div
              onClick={() => {
                Route_push();
                history?.push(`/${selectedLanguage}/about`);
              }}
            >
              Overview
            </div>
            <div
              onClick={() => {
                setDropEight(!drop8);
                // Route_push();
                // history.push(
                //   `/${selectedLanguage}/about/the-temple/main-temple`
                // );
              }}
            >
              <span>The Temple</span>
              {drop8 ? (
                <img
                  src={sd_minus_icon}
                  alt="-"
                  onClick={() => {
                    setDropEight(false);
                  }}
                ></img>
              ) : (
                <img
                  src={sd_plus_icon}
                  onClick={() => {
                    setDropEight(true);
                  }}
                />
              )}
            </div>
            <div className={drop8 ? "drop-8 block" : "drop-8 none"}>
              {NavBarData("about")?.["the-temple"]?.map((data, i) => {
                return (
                  <div
                    key={i}
                    onClick={() => {
                      Route_push();
                      history?.push(
                        `/${selectedLanguage}/about/the-temple/${data?.path}`
                      );
                    }}
                  >
                    {data?.name}
                  </div>
                );
              })}
            </div>

            <div
              onClick={() => {
                setDropNine(!drop9);
              }}
            >
              <span>The Temple History</span>
              {drop9 ? (
                <img
                  src={sd_minus_icon}
                  alt="-"
                  onClick={() => {
                    setDropNine(false);
                  }}
                ></img>
              ) : (
                <img
                  src={sd_plus_icon}
                  onClick={() => {
                    setDropNine(true);
                  }}
                />
              )}
            </div>
            <div className={drop9 ? "drop-9 block" : "drop-9 none"}>
              {NavBarData("about")?.["temple-story"]?.map((data, i) => {
                return (
                  <div
                    key={i}
                    onClick={() => {
                      Route_push();

                      history?.push(
                        `/${selectedLanguage}/about/the-temple-history/${data?.path}`
                      );
                    }}
                  >
                    {data?.name}
                  </div>
                );
              })}
            </div>

            <div
              onClick={() => {
                setDropTen(!drop10);
              }}
            >
              <span>General Information</span>
              {drop10 ? (
                <img
                  src={sd_minus_icon}
                  alt="-"
                  onClick={() => {
                    setDropTen(false);
                  }}
                ></img>
              ) : (
                <img
                  src={sd_plus_icon}
                  onClick={() => {
                    setDropTen(true);
                  }}
                />
              )}
            </div>
            <div className={drop10 ? "drop-10 block" : "drop-10 none"}>
              {NavBarData("about")?.["general-information"]?.map((data, i) => {
                return (
                  <div
                    key={i}
                    onClick={() => {
                      Route_push();

                      history?.push(
                        `/${selectedLanguage}/about/general-information/${data?.path}`
                      );
                    }}
                  >
                    {data?.name}
                  </div>
                );
              })}
            </div>
          </div>

          <div
            className="between"
            onClick={() => {
              if (drop2) {
                setDropTwo(false);
              }
              setDropOne(!drop1);
            }}
          >
            Sevas & Darshanam
            {drop1 ? (
              <img src={sd_minus_icon} alt="-"></img>
            ) : (
              <img src={sd_plus_icon} alt="+" />
            )}
          </div>

          <div className={drop1 ? "drop-one block" : "drop-one none"}>
            <div
              onClick={() => {
                Route_push();
                history?.push(`/${selectedLanguage}/sevas-and-darshanam`);
              }}
            >
              Overview
            </div>

            <div
              onClick={() => {
                setDrop11(!drop11);
              }}
            >
              Darshanam
              {drop11 ? (
                <img src={sd_minus_icon} alt="-"></img>
              ) : (
                <img src={sd_plus_icon} alt="+" />
              )}
            </div>
            <div className={drop11 ? "drop-11 block" : "drop-11 none"}>
              {NavBarData("seva-darshanam")?.["Darshanam"]?.map((data, i) => {
                return (
                  <div
                    key={i}
                    onClick={() => {
                      Route_push();

                      history?.push(
                        `/${selectedLanguage}/sevas-and-darshanam/darshanam/${data?.path}`
                      );
                    }}
                  >
                    {data?.name}
                  </div>
                );
              })}
            </div>
            <div
              onClick={() => {
                setDrop12(!drop12);
              }}
            >
              Pratyaksha Seva
              {drop12 ? (
                <img src={sd_minus_icon} alt="-"></img>
              ) : (
                <img src={sd_plus_icon} alt="+" />
              )}
            </div>
            <div className={drop12 ? "drop-12 block" : "drop-12 none"}>
              {NavBarData("seva-darshanam")?.["pratyakshaseva"]?.map(
                (data, i) => {
                  return (
                    <div
                      key={i}
                      onClick={() => {
                        Route_push();

                        history?.push(
                          `/${selectedLanguage}/sevas-and-darshanam/pratyaksha-seva/${data?.path}`
                        );
                      }}
                    >
                      {data?.name}
                    </div>
                  );
                }
              )}

              {/* {praytakshaSevaNav.map((item, i) => {
                return (
                  <div
                    key={"pratm" + i}
                    onClick={() => {
                      Route_push();

                      toast.info(
                        "Pratyaksha sevas info is not available at the moment."
                      );
                    }}
                  >
                    {item?.key}{" "}
                  </div>
                );
              })} */}
            </div>

            <div
              onClick={() => {
                setDropTwo(!drop2);
              }}
            >
              Paroksha Seva
              {drop2 ? (
                <img src={sd_minus_icon} alt="-" onClick={() => {}}></img>
              ) : (
                <img src={sd_plus_icon} alt="+" />
              )}
            </div>
          </div>
          <div className={drop2 ? "drop-two block" : "drop-two none"}>
            {NavBarData("seva-darshanam")?.["parokshaseva"]?.map((data, i) => {
              return (
                <div
                  key={i}
                  onClick={() => {
                    Route_push();
                    history.push(
                      `/${selectedLanguage}/sevas-and-darshanam/paroksha-seva/${data?.path}`
                    );
                  }}
                >
                  {data?.name}
                </div>
              );
            })}

            {/* {praytakshaSevaNav.map((item, i) => {
              return (
                <div
                  key={"pratm" + i}
                  onClick={() => {
                    Route_push();

                    toast.info(
                      "Paroksha sevas info is not available at the moment."
                    );
                  }}
                >
                  {item?.key}{" "}
                </div>
              );
            })} */}
          </div>

          <div
            className="between"
            onClick={() => {
              setDropFour(!drop4);
            }}
          >
            Donations
            {drop4 ? (
              <img src={sd_minus_icon} alt="-"></img>
            ) : (
              <img src={sd_plus_icon} alt="+" />
            )}
          </div>

          <div className={drop4 ? "drop-four block" : "drop-four none"}>
            <div
              onClick={() => {
                Route_push();
                history?.push(`/${selectedLanguage}/donations`);
              }}
            >
              Overview
            </div>
            {NavBarData("donation")?.map((data, i) => {
              return (
                <div
                  key={i}
                  onClick={() => {
                    Route_push();

                    history?.push(
                      `/${selectedLanguage}/donations/${data?.path}`
                    );
                  }}
                >
                  {data?.name}
                </div>
              );
            })}
          </div>

          <div
            className="between"
            onClick={() => {
              setDropFive(!drop5);
            }}
          >
            Online Booking
            {drop5 ? (
              <img src={sd_minus_icon} alt="-"></img>
            ) : (
              <img src={sd_plus_icon} alt="+" />
            )}
          </div>

          <div className={drop5 ? "drop-five block" : "drop-five none"}>
            <div
              onClick={() => {
                Route_push();
                history?.push(`/${selectedLanguage}/online-booking`);
              }}
            >
              Overview
            </div>
            {NavBarData("online-data")?.map((data, i) => {
              return (
                <div
                  key={i}
                  onClick={() => {
                    Route_push();
                    if (localStorage.getItem("accessToken")) {
                      history?.push(
                        `/${selectedLanguage}/devotee-app/online-booking/${data?.path}`
                      );
                    } else {
                      history?.push(
                        `/${selectedLanguage}/devotee/signin?to=devotee-app/online-booking/${data?.path}`
                      );
                    }
                  }}
                >
                  {data?.name}
                </div>
              );
            })}
          </div>

          <div
            className="between"
            onClick={() => {
              setDropSix(!drop6);
            }}
          >
            Media Room
            {drop6 ? (
              <img src={sd_minus_icon} alt="-"></img>
            ) : (
              <img src={sd_plus_icon} alt="+" />
            )}
          </div>

          <div className={drop6 ? "drop-six block" : "drop-six none"}>
            <div
              onClick={() => {
                Route_push();
                history?.push(`/${selectedLanguage}/media-room`);
              }}
            >
              Overview
            </div>
            <div
              onClick={() => {
                Route_push();

                history?.push(`/${selectedLanguage}/media-room/media-kit`);
              }}
            >
              Media Kit
            </div>
            <div>
              <span>Gallery </span>
              {drop13 ? (
                <img
                  src={sd_minus_icon}
                  alt="-"
                  onClick={() => {
                    setDrop13(false);
                  }}
                ></img>
              ) : (
                <img
                  src={sd_plus_icon}
                  onClick={() => {
                    setDrop13(true);
                  }}
                />
              )}
            </div>
            <div className={drop13 ? "drop-13 block" : "drop-13 none"}>
              {NavBarData("media")?.["gallery"]?.map((data, i) => {
                return (
                  <div
                    key={i}
                    onClick={() => {
                      Route_push();
                      history?.push(
                        `/${selectedLanguage}/media-room/gallery/${data?.path}`
                      );
                    }}
                  >
                    {data?.name}
                  </div>
                );
              })}
            </div>

            <div>
              <span>Whats New </span>
              {drop14 ? (
                <img
                  src={sd_minus_icon}
                  alt="-"
                  onClick={() => {
                    setDrop14(false);
                  }}
                ></img>
              ) : (
                <img
                  src={sd_plus_icon}
                  onClick={() => {
                    setDrop14(true);
                  }}
                />
              )}
            </div>

            <div className={drop14 ? "drop-14 block" : "drop-14 none"}>
              {NavBarData("media")?.["whats-new"]?.map((data, i) => {
                return (
                  <div
                    key={i}
                    onClick={() => {
                      Route_push();

                      history?.push(
                        `/${selectedLanguage}/media-room/whats-new/${data?.path}`
                      );
                    }}
                  >
                    {data?.name}
                  </div>
                );
              })}
              <div
                onClick={() => {
                  Route_push();

                  history?.push(
                    `/${selectedLanguage}/media-room/whats-new/temple-diary`
                  );
                }}
              >
                Temple Dairy{" "}
              </div>
            </div>

            <div>
              <span
                onClick={() => {
                  // Route_push();
                  // history.push(`/${selectedLanguage}/media-room/press`);
                }}
              >
                Press{" "}
              </span>
              {drop15 ? (
                <img
                  src={sd_minus_icon}
                  alt="-"
                  onClick={() => {
                    setDrop15(false);
                  }}
                ></img>
              ) : (
                <img
                  src={sd_plus_icon}
                  onClick={() => {
                    setDrop15(true);
                  }}
                />
              )}
            </div>

            <div className={drop15 ? "drop-15 block" : "drop-15 none"}>
              {NavBarData("media")?.["press"]?.map((data, i) => {
                return (
                  <div
                    key={i}
                    onClick={() => {
                      Route_push();

                      history?.push(
                        `/${selectedLanguage}/media-room/press/${data?.path}`
                      );
                    }}
                  >
                    {data?.name}
                  </div>
                );
              })}
            </div>
            <div>
              <span
                onClick={() => {
                  Route_push();
                  history.push(`/${selectedLanguage}/media-room/rti-act`);
                }}
              >
                RTI Act{" "}
              </span>
            </div>
          </div>

          <div
            className="between"
            onClick={() => {
              setDropSeven(!drop7);
            }}
          >
            Support
            {drop7 ? (
              <img src={sd_minus_icon} alt="-"></img>
            ) : (
              <img src={sd_plus_icon} alt="+" />
            )}
          </div>
          <div
            className={drop7 ? "drop-seven block" : "drop-seven none"}
            style={{ marginBottom: "50px" }}
          >
            <div
              onClick={() => {
                Route_push();

                history?.push(`/${selectedLanguage}/support`);
              }}
            >
              Overview
            </div>
            {NavBarData("support")?.map((data, i) => {
              return (
                <div
                  key={i}
                  onClick={() => {
                    Route_push();
                    history?.push(`/${selectedLanguage}/support/${data?.path}`);
                  }}
                >
                  {data?.name}
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="inner-footer">
        <img
          onClick={() => {
            window.open(
              "https://www.facebook.com/SrisailaDevasthanamOfficial",
              "_blank"
            );
          }}
          src={SD_facebook_icon}
          alt="icon"
        />
        <img
          onClick={() => {
            window.open(
              "https://twitter.com/AnnavaramDevas1?t=CMCZDtumNhJOWCcXQL26JQ&s=09",
              "_blank"
            );
          }}
          src={SD_twitter_icon}
          alt="icon"
        />

        <img
          onClick={() => {
            window.open("https://www.instagram.com/sbmsdevasthanam/", "_blank");
          }}
          src={SD_instagram_icon}
          alt="icon"
        />
        <img
          onClick={() => {
            window.open(
              "https://www.youtube.com/channel/UCNIzL2dT2gZxQae2LHXvl0A",
              "_blank"
            );
          }}
          src={SD_youtube_icon}
          alt="icon"
        />
      </div>
    </div>
  );
};
export default withRouter(Sd_mobile_left_sider);
