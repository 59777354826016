import React, { useState, useContext, useEffect, useRef } from "react";
import {
  sd_btn_loading,
  SD_Dropdown_Arrow_icon,
  SD_Right_Arrow_white,
} from "../../../../Assets/imageList";
import "../../../../Utils/sd-styles/sd-css/main.css";
import Sd_loading from "../../../../Components/sd-common-components/sd-loading";
import { SD_Context } from "../../../../context/sd-context";

import "../../../../Utils/sd-styles/sd-dashboard.scss";
//import "react-day-picker/lib/style.css";
// import moment from "moment";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PratyakshaSevaService from "../../../../services/sd-pratyakshaseva.service";
import DatePicker from "react-datepicker";
// import Sd_DashboardService from "../../../../services/sd-dashboard.service";
import moment from "moment";
import ParokshaSevaService from "../../../../services/sd-parokshaseva.service";
import PhoneInput from "react-phone-input-2";
import AddonService from "../../../../services/sd-add-on.service";
import Addons from "../../../../Components/add-ons/Addons";
import { bookingTimeIsWithinProductTime } from "../../../../Utils/sd-slot-management/slot-management";
import { checkDuplicateStrings } from "../../../../Utils/sd-common/sd-common";
const supportedLanguages = ["en-in", "jp-in", "de-in", "es-in"];

const Sd_dashboard_pratyakshaseva_seva_details = ({ history, match }) => {
  //-------------------------------------------------------------states-----------------------------------------------------------//
  const [loading, setLoading] = useState(true);
  const {
    setActivePath,
    setActive,
    currentBookingPratyaksha,
    setcurrentBookingPratyaksha,
    pratyakshaSevaData,
    setPratyakshaSevaData,
    devoteeList,
    userData,
    logout,
    addonsApiRes,
    setAddonsApiRes,
    addonsList,
    setAddonsList,
    addonIds,
    setAddonIds,
    addonSlotIds,
    setAddonSlotIds,
    addonSlotsData,
    setAddonSlotsData,
    setAddonTypes,
    selectedCard,
    setSelectedCard,
  } = useContext(SD_Context);
  const ref = useRef(null);
  const [selectedlanguage, setSelectedlanguage] = useState("en-in");
  const [noPersons, setNoperons] = useState(1);
  const [countArray, setCountArray] = useState([]);
  const [checkbox, setCheckbox] = useState(false);
  const [autoFill, setAutoFill] = useState("none");
  const [personStates, setPersonStates] = useState([
    {
      id: 1,
      name: "",
      idProofType: "",
      idProofNumber: "",
    },
  ]);
  const [err, setErr] = useState([]);
  const [gothram, setGothram] = useState("");
  const [show, setShow] = useState(false);
  // const [selectedValue, setSelectedValue] = useState(null);
  const [selectedArray, setSelectedArray] = useState([]);
  const [slot, setSlot] = useState("");
  const [slotLoading, setSlotLoading] = useState(false);
  const [startDate, setStartDate] = useState("");
  // const [abhishekamType, setAbhishekamType] = useState("single");
  const [slotsData, setSlotsData] = useState([]);
  const [highlightDates, setHighlightDates] = useState([]);
  const [excludeDates, setExcludeDates] = useState([]);
  const templeID = process.env.REACT_APP_TEMPLE ?? "SDMSD";
  const [mobile, setMobile] = useState("91");
  //-------------------------------------------------------------states-----------------------------------------------------------//

  //-------------------------------------------------------------consts-----------------------------------------------------------//
  const termsList = [
    "For all Sevas & Pujas, Male sevadars shall wear Pancha with Kanduva and females shall wear Saree with Blouse invariably. Other dresses will not be allowed",
    "Only couple (Wife & Husband) or single person will be allowed to perform the Seva on one ticket.",
    "Children below 10 years of age are also permitted to follow their parents.",
    "Other kith and kin will not be allowed along with sevadars of any Seva ticket holders. Those kith and kin should take Rs.500/- ticket each to enter into the temple only for having Darshanam and not to perform the Seva",
    "An ID proof on the name of the ticket holder must also be shown.",
    "This ticket is non-transferable and it cannot be cancelled.",
    "Mobiles are strictly prohibited in Temple Premises.",
  ];

  // const abhishekamTimes = ["6:30 AM", "12:30 PM", "6:30 PM"];

  // const kumkumarchanaTimes = [
  //   "6:30 AM - 7:30 AM",
  //   "7:30 AM - 8:30 AM",
  //   "8:30 AM - 9:30 AM",
  //   "9:30 AM - 10:30 AM",
  //   "10:30 AM - 11:30 AM",
  //   "11:30 PM - 12:30 PM",
  //   "12:30 PM - 1:30 PM",
  //   "1:30 PM -2:00 PM",
  // ];
  //-------------------------------------------------------------consts-----------------------------------------------------------//

  //-------------------------------------------------------------useeffects-----------------------------------------------------------//
  useEffect(() => {
    setActive("online-booking-pratyaksha-enter-details");
    setActivePath("Onlinebooking-Pratyaksha Seva-Enter Details");
    setLoading(true);
    // if (!currentBookingPratyaksha) {
    //   history?.push(`/${selectedlanguage}/devotee-app/`);
    // }
    let path = new URLSearchParams(history?.location?.search).get("state");
    if (path === "clear") {
      clearAddon();
    }
    localStorage.removeItem("payment-context");
    if (
      supportedLanguages.includes(
        history?.location?.pathname?.split?.("/")?.[1]
      )
    ) {
      setSelectedlanguage(history?.location?.pathname?.split?.("/")?.[1]);
    } else {
      setSelectedlanguage("en-in");
    }
    if (currentBookingPratyaksha) {
      // currentBookingPratyaksha?.abhishekamType &&
      //   setAbhishekamType(currentBookingPratyaksha?.abhishekamType);
      setNoperons(currentBookingPratyaksha?.noPersons);
      setPersonStates(currentBookingPratyaksha?.personStates);
      setGothram(currentBookingPratyaksha?.gothram);
      setStartDate(currentBookingPratyaksha?.startDate);
      setSelectedArray(currentBookingPratyaksha?.selectedArray);
      currentBookingPratyaksha?.slot && setSlot(currentBookingPratyaksha?.slot);
      setSlot(currentBookingPratyaksha?.slot);
      setSlotsData(currentBookingPratyaksha?.slotsData);
      setMobile(currentBookingPratyaksha?.mobile);
      setAddonIds(currentBookingPratyaksha?.addonIds);
      setAddonSlotIds(currentBookingPratyaksha?.addonSlotIds);
      setAddonsApiRes(currentBookingPratyaksha?.addonsApiRes);
      setAddonsList(currentBookingPratyaksha?.addonsList);
      setAddonSlotsData(currentBookingPratyaksha?.addonSlotsData);
      setSelectedCard(currentBookingPratyaksha?.selectedCard);
      let count = [];
      for (
        let i = 1;
        i <= currentBookingPratyaksha?.selectedArray[0].noOfPeople;
        i++
      ) {
        count.push(i);
      }
      setCountArray(count);
      setLoading(false);
    } else {
      if (pratyakshaSevaData.length === 0) {
        getData();
      } else {
        let finder = pratyakshaSevaData.find(
          (item) =>
            item?.name?.replace?.(/\s/g, "-").toLowerCase() ===
            match.params.type
        );
        if (!finder?.onlineEnabled) {
          history?.push(
            `/${selectedlanguage}/devotee-app/online-booking/pratyaksha-seva`
          );
        }
        if (finder?.noOfPeople) {
          const count = [];
          for (var i = 1; i <= finder?.noOfPeople; i++) {
            count.push(i);
          }
          setCountArray(count);
        }
        setSelectedArray([finder]);

        setLoading(false);
      }
    }
  }, []);

  useEffect(() => {
    listFunction();
  }, [noPersons]);

  useEffect(() => {
    if (selectedArray && selectedArray[0]?.id) {
      getQuota(selectedArray[0]);
    }
  }, [selectedArray]);
  //-------------------------------------------------------------useeffects-----------------------------------------------------------//

  //-------------------------------------------------------------functions-----------------------------------------------------------//

  const getData = async () => {
    try {
      let data = await PratyakshaSevaService.getAllvalidOnline(templeID);
      data = data?.filter(
        (item) => item.onlineEnabled && item.sevaType === "pratyakshaSeva"
      );
      if (data) {
        setPratyakshaSevaData(
          data.filter(
            (item) => item.sevaType === "pratyakshaSeva" && item.onlineEnabled
          )
        );
        let finder = data?.find(
          (item) =>
            item?.name?.replace?.(/\s/g, "-").toLowerCase() ===
            match.params.type
        );
        if (!finder?.onlineEnabled) {
          history?.push(
            `/${selectedlanguage}/devotee-app/online-booking/pratyaksha-seva`
          );
        }
        if (finder?.noOfPeople) {
          const count = [];
          for (var i = 1; i <= finder?.noOfPeople; i++) {
            count.push(i);
          }
          setCountArray(count);
        }
        setSelectedArray([finder]);
      }
      setLoading(false);
    } catch (e) {
      ref.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });

      toast.error(e.message);
      if (e.message === "Session Expired") {
        logout();

        history?.push(
          `/${selectedlanguage}/devotee/signin?to=devotee-app/online-booking/pratyaksha-seva`
        );
      }
      setLoading(false);

      return;
    }
  };

  const clearAddon = () => {
    setAddonIds([]);
    setAddonSlotIds([]);
    setAddonSlotsData([]);
    setAddonsApiRes([]);
    setAddonsList([]);
  };

  const getAddon = async (data, date) => {
    setLoading(true);
    let addonTypeArray = [];
    try {
      let addonres = await AddonService.fetchAddon(
        templeID,
        Number(data?.id),
        "SEVA",
        moment(date).format("YYYY-MM-DD")
      );
      addonres?.map((item) => {
        if (!addonTypeArray?.includes(item?.childType)) {
          addonTypeArray = [...addonTypeArray, item?.childType];
        }
      });
      setAddonTypes(addonTypeArray);
      setAddonsApiRes(addonres);
      setLoading(false);
    } catch (e) {
      toast.warning(e.message);
      if (e.message === "Session Expired") {
        logout();

        history?.push(
          `/${selectedlanguage}/devotee/signin?to=devotee-app/online-booking/pratyaksha-seva`
        );
      }
      setLoading(false);
    }
  };

  // const handleFileUpload = async (e) => {
  //   try {
  //     if (e?.target?.files[0]?.size / 1024 / 1024 > 5) {
  //       // setUploadFlag(false);
  //       // document.getElementById(`${e?.target?.id}`).value = "";

  //       throw new Error(
  //         "Please make sure the file you are attaching are below 5 mb "
  //       );
  //     }
  //     let formData = new FormData();
  //     formData.append("file", e.target.files[0]);
  //     let res = await Sd_DashboardService.upload_document(
  //       formData,
  //       "pratyakshaSeva",
  //       templeID
  //     );
  //     toast.info("File Uploaded");
  //     return res;
  //   } catch (e) {
  //     toast.error(e.message);
  //     // document.getElementById(`${e?.target?.id}`).value = "";

  //     if (e.message === "Session Expired") {
  //       logout();

  //       history?.push(
  //         `/${selectedlanguage}/devotee/signin?to=devotee-app/online-booking/pratyaksha-seva`
  //       );
  //     }
  //     return false;
  //   }
  //   // setUploadFlag(false);
  // };

  const buttonsArrayFunction = () => {
    return selectedArray?.map((item, i) => {
      return (
        <div
          className="button-div"
          style={{ display: "flex" }}
          key={i + "buttons-prs"}
        >
          <p>
            {item?.name}
            <span className="mr-3">-</span>Rs.
            {item?.price}
          </p>
        </div>
      );
    });
  };
  const listFunctionSevas = () => {
    // console.log("in pratyaksha seva");
    let temp = pratyakshaSevaData.filter((item) => item.onlineEnabled);
    let array = temp.map((item, i) => {
      if (!item?.onlineEnabled || item?.maxOnlineBookings === 0) {
        return;
      } else {
        let finder = null;
        for (let i = 0; i < selectedArray.length; i++) {
          if (selectedArray[i].name === item?.name) {
            finder = true;
          }
        }
        return (
          <div
            key={i + "list-prs"}
            className={finder ? "option-class sd-cyan" : "option-class"}
            onClick={() => {
              setSelectedArray([item]);
              setSlot("");
              setSlotsData([]);
              setStartDate("");
              setShow(false);
            }}
          >
            {item?.name}
            <span className="mr-3">-</span> Rs.
            {item.price}
          </div>
        );
      }
    });
    return array;
  };
  const fnCalculateAge = (userDateinput) => {
    let birthDate = new Date(userDateinput);
    let difference = Date.now() - birthDate.getTime();
    let ageDate = new Date(difference);
    let calculatedAge = Math.abs(ageDate.getUTCFullYear() - 1970);
    return calculatedAge;
  };
  const handleChange = (index, event, name) => {
    if (name === "idProofType") {
      let data = [...personStates];

      let value = data[index][`${name}`];
      data[index][`${name}`] = event.target.value;
      if (value !== event.target.value) {
        data[index][`idProofNumber`] = "";
      }
      setPersonStates(data);
    } else if (name === "idProofNumber") {
      let data = [...personStates];
      data[index][`idProofNumber`] = event.target.value
        ?.replaceAll?.(" ", "")
        ?.toUpperCase();
      setPersonStates(data);
    } else {
      let data = [...personStates];
      data[index][`${name}`] = event.target.value;
      setPersonStates(data);
    }
  };
  const listFunction = () => {
    if (noPersons === personStates.length) {
      return;
    }
    if (noPersons < personStates.length) {
      let arr = [...personStates];

      let data = err.filter((e) => {
        if (
          e.type !== `${personStates.length}-name` &&
          e.type !== `${personStates.length}-idProofType` &&
          e.type !== `${personStates.length}-idProofNumber`
        ) {
          return e;
        }
      });
      setErr(data);
      arr.pop();

      setPersonStates([...arr]);
      return;
    }
    if (noPersons > personStates.length) {
      let arr = [...personStates];
      arr.push({
        id: personStates.length + 1,
        name: "",
        idProofType: "",
        idProofNumber: "",
      });
      setPersonStates([...arr]);
      return;
    }
  };
  function addDays(date, days) {
    const copy = new Date(Number(date));
    copy.setDate(date.getDate() + days);
    return copy;
  }
  const autoFillFunction = (name, i) => {
    let array = [];
    let userOnClick = () => {
      let data = [...personStates];
      data[i]["name"] =
        userData?.firstName || userData?.lastName
          ? `${userData?.firstName} ${userData?.lastName}`
          : userData?.displayName;

      data[i]["idProofType"] = userData?.idProofType
        ? userData?.idProofType
        : "";
      data[i]["idProofNumber"] = userData?.idProofNumber
        ? userData?.idProofNumber
        : "";
      setGothram(userData?.gothram ?? "");
      setMobile(userData?.phone?.replace("+", "") ?? "");
      setPersonStates(data);
      let datas = err.filter((e) => {
        if (
          e.type !== `${i + 1}-name` &&
          e.type !== `${i + 1}-idProofNumber` &&
          e.type !== `${i + 1}-idProofType`
        ) {
          return e;
        }
      });
      setErr(datas);
      setAutoFill("none");
    };
    let a = personStates.filter((e) => {
      if (
        e?.name?.toLowerCase() ===
        `${userData?.firstName?.toLowerCase()} ${userData?.lastName?.toLowerCase()}`
      ) {
        return e;
      }
    });

    if (
      userData?.firstName?.toLowerCase()?.includes(name?.toLowerCase()) ||
      userData?.lastName?.toLowerCase()?.includes(name?.toLowerCase())
    ) {
      if (a?.length === 0) {
        array.push(
          <p
            className="sd-autofill-div"
            onClick={() => {
              userOnClick();
            }}
          >
            <p
              style={{ textTransform: "capitalize" }}
              onClick={() => {
                userOnClick();
              }}
            >
              Name:{" "}
              {userData?.firstName
                ? userData?.firstName
                : userData?.displayName}
              <span style={{ marginLeft: "3px" }}>{userData?.lastName}</span>
            </p>
            {userData?.dateOfBirth && (
              <p
                onClick={() => {
                  userOnClick();
                }}
              >
                Age:
                {fnCalculateAge(userData?.dateOfBirth)} yrs
              </p>
            )}
            {userData?.gender && <p>Gender: {userData?.gender}</p>}
          </p>
        );
      }
    }
    if (devoteeList !== null) {
      devoteeList.forEach((items) => {
        if (
          items?.firstName?.toLowerCase()?.includes(name?.toLowerCase()) ||
          items?.lastName?.toLowerCase()?.includes(name?.toLowerCase())
        ) {
          let a = personStates.filter((e) => {
            if (e?.name === `${items?.firstName} ${items?.lastName}`) {
              return e;
            }
          });
          if (a?.length === 0) {
            let onCLick = () => {
              let data = [...personStates];
              data[i]["name"] =
                items?.firstName || items?.lastName
                  ? `${items?.firstName} ${items?.lastName}`
                  : "";
              data[i]["idProofType"] = items?.idProofType ?? "";
              data[i]["idProofNumber"] = items?.idProofNumber ?? "";

              setPersonStates(data);
              let datas = err.filter((e) => {
                if (
                  e.type !== `${i + 1}-name` &&
                  e.type !== `${i + 1}-idProofNumber` &&
                  e.type !== `${i + 1}-idProofType`
                ) {
                  return e;
                }
              });
              setErr(datas);
              setAutoFill("none");
            };
            array.push(
              <p
                key={items.id + "autofill-prs"}
                className="sd-autofill-div"
                onClick={() => {
                  onCLick();
                }}
              >
                <p
                  onClick={() => {
                    onCLick();
                  }}
                >
                  Name: {items?.firstName}
                  <span style={{ marginLeft: "3px" }}>{items?.lastName}</span>
                </p>
                <p
                  onClick={() => {
                    onCLick();
                  }}
                >
                  Age:
                  {fnCalculateAge(items?.dob)} yrs
                </p>
                <p>Gender: {items?.gender}</p>
              </p>
            );
          }
        }
      });
    }

    if (array.length !== 0) {
      return (
        <div className="sd-autofill bg">
          {array.map((item) => {
            return item;
          })}
        </div>
      );
    } else {
      if (name === "" && a?.length === 0) {
        let demo = (
          <p
            className="sd-autofill-div"
            onClick={() => {
              userOnClick();
            }}
          >
            <p
              style={{ textTransform: "capitalize" }}
              onClick={() => {
                userOnClick();
              }}
            >
              Name:{" "}
              {userData?.firstName
                ? userData?.firstName
                : userData?.displayName}
              <span style={{ marginLeft: "3px" }}>{userData?.lastName}</span>
            </p>
            {userData?.dateOfBirth && (
              <p
                onClick={() => {
                  userOnClick();
                }}
              >
                Age:
                {fnCalculateAge(userData?.dateOfBirth)} yrs
              </p>
            )}
            {userData?.gender && (
              <p
                onClick={() => {
                  userOnClick();
                }}
              >
                Gender: {userData?.gender}
              </p>
            )}
          </p>
        );

        let a = [...devoteeList, demo];
        return (
          <div className="sd-autofill bg ani">
            {a.map((item) => {
              return item;
            })}{" "}
          </div>
        );
      } else {
        return "";
      }
    }
  };

  const getSlotsDetails = () => {
    if (slotsData?.length === 0 || !slotsData) {
      return <p>No Slots available</p>;
    }
    return slotsData
      ?.filter((item) => moment(startDate).format("YYYY-MM-DD") === item?.date)
      ?.sort((a, b) =>
        a.productSlot?.startTime.localeCompare(b.productSlot?.startTime)
      )
      ?.map((item, i) => {
        if (item?.availableQuantity < 1) {
          return;
        }
        return (
          <div
            key={i + "slots-prs"}
            onClick={() => {
              if (item?.availableQuantity < 1) {
                return;
              }
              setSlot(item);
              if (err?.find((e) => e.type === `slot`)) {
                let data = err.filter((e) => e.type !== "slot");
                setErr(data);
              }
            }}
          >
            <div
              className="sd-darshanam-box text-center"
              style={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                border:
                  err.find((e) => e.type === "slot") ||
                  item?.availableQuantity < 1
                    ? "1px solid red"
                    : "",
              }}
              onClick={() => {
                if (personStates?.length > item?.availableQuantity) {
                  return;
                }
                setSlot(item);
                if (err?.find((e) => e.type === `slot`)) {
                  let data = err.filter((e) => e.type !== "slot");
                  setErr(data);
                }
              }}
            >
              <p
                className="sd-darshanam-time"
                style={{ marginBottom: "0px", maxWidth: "85px" }}
              >
                <span style={{ marginRight: "3px", fontWeight: "600" }}>
                  {item.availableQuantity}
                </span>
                <span style={{ fontWeight: "400" }}> Available </span>
              </p>
              <div className="sd-side-heading-2 sd-acc">
                {window.innerWidth < 650 ? (
                  <span
                    style={{
                      fontWeight: "600",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Slot <br /> (
                    {moment(item?.productSlot?.startTime, "HH:mm:ss").format(
                      "hh:mm A"
                    ) ===
                    moment(item?.productSlot?.endTime, "HH:mm:ss").format(
                      "hh:mm A"
                    ) ? (
                      moment(item?.productSlot?.startTime, "HH:mm:ss").format(
                        "hh:mm A"
                      )
                    ) : (
                      <>
                        {moment(
                          item?.productSlot?.startTime,
                          "HH:mm:ss"
                        ).format("hh:mm A")}{" "}
                        to
                        <span style={{ marginLeft: "5px" }}>
                          {moment(
                            item?.productSlot?.endTime,
                            "HH:mm:ss"
                          ).format("hh:mm A")}
                          )
                        </span>
                      </>
                    )}
                  </span>
                ) : (
                  <span
                    style={{
                      fontWeight: "600",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Slot - (
                    {moment(item?.productSlot?.startTime, "HH:mm:ss").format(
                      "hh:mm A"
                    ) ===
                    moment(item?.productSlot?.endTime, "HH:mm:ss").format(
                      "hh:mm A"
                    ) ? (
                      moment(item?.productSlot?.startTime, "HH:mm:ss").format(
                        "hh:mm A"
                      )
                    ) : (
                      <>
                        {" "}
                        {moment(
                          item?.productSlot?.startTime,
                          "HH:mm:ss"
                        ).format("hh:mm A")}{" "}
                        -
                        {moment(item?.productSlot?.endTime, "HH:mm:ss").format(
                          "hh:mm A"
                        )}
                        )
                      </>
                    )}
                  </span>
                )}
              </div>
              <input
                className="sd-darshanam-radio"
                type="radio"
                id={"slot" + 11}
                name=""
                value={slot}
                onClick={() => {
                  if (personStates?.length > item?.availableQuantity) {
                    return;
                  }
                  setSlot(item);
                  if (err?.find((e) => e.type === `slot`)) {
                    let data = err.filter((e) => e.type !== "slot");
                    setErr(data);
                  }
                }}
                checked={slot.id === item?.id}
              />
            </div>
          </div>
        );
      });
  };

  const getDates = (startDate, endDate, quotaDates, notAvailableDates) => {
    let date = startDate;
    let dates = [];
    while (date <= endDate) {
      if (
        !quotaDates?.includes(moment(date, "YYYY-MM-DD").format("YYYY-MM-DD"))
      ) {
        dates.push(new Date(date));
      }
      date = moment(date, "YYYY-MM-DD").add(1, "days").format("YYYY-MM-DD");
    }
    setExcludeDates([...notAvailableDates, ...dates]);
  };

  const getQuota = async (data) => {
    setSlotLoading(true);
    let quotaDates = [];
    let availableDates = [];
    let availableDatesString = [];
    let almostBookedDates = [];
    let almostBookedDatesString = [];
    let notAvailableDates = [];
    let notAvailableDatesString = [];
    try {
      let res = await PratyakshaSevaService.getDailyQuota(
        moment().add(data?.minOnlineHours, "hours").format("YYYY-MM-DD"),
        moment().add(data?.maxOnlineHours, "hours").format("YYYY-MM-DD"),
        Number(data?.id),
        data?.onlineEnabled,
        templeID
      );
      setSlotsData(
        res?.filter((item) =>
          bookingTimeIsWithinProductTime(
            item?.date,
            item?.productSlot?.startTime,
            data?.minOnlineHours || 1,
            data?.maxOnlineHours
          )
        )
      );
      res
        ?.filter((item) =>
          bookingTimeIsWithinProductTime(
            item?.date,
            item?.productSlot?.startTime,
            data?.minOnlineHours || 1,
            data?.maxOnlineHours
          )
        )
        ?.map((item) => {
          quotaDates.push(item?.date);
          if (item?.quantity > 0) {
            if (Number(item?.availableQuantity / item?.quantity) * 100 > 50) {
              availableDates.push(new Date(item?.date));
              availableDatesString.push(new Date(item?.date).toString());
              if (
                notAvailableDatesString.includes(
                  new Date(item?.date).toString()
                )
              ) {
                notAvailableDates.splice(
                  notAvailableDates.indexOf(new Date(item?.date).toString()),
                  1
                );
                notAvailableDatesString.splice(
                  notAvailableDatesString.indexOf(
                    new Date(item?.date).toString()
                  ),
                  1
                );
              }
            } else if (
              Number(item?.availableQuantity / item?.quantity) * 100 <= 50 &&
              Number(item?.availableQuantity / item?.quantity) * 100 > 0
            ) {
              almostBookedDates.push(new Date(item?.date));
              almostBookedDatesString.push(new Date(item?.date).toString());
              if (
                notAvailableDatesString.includes(
                  new Date(item?.date).toString()
                )
              ) {
                notAvailableDates.splice(
                  notAvailableDates.indexOf(new Date(item?.date).toString()),
                  1
                );
                notAvailableDatesString.splice(
                  notAvailableDatesString.indexOf(
                    new Date(item?.date).toString()
                  ),
                  1
                );
              }
            } else {
              if (
                !availableDatesString.includes(new Date(item?.date).toString())
              ) {
                if (
                  !almostBookedDatesString.includes(
                    new Date(item?.date).toString()
                  )
                ) {
                  notAvailableDates.push(new Date(item?.date));
                  notAvailableDatesString.push(new Date(item?.date).toString());
                }
              }
            }
          }
        });
      notAvailableDates?.map((date) => {
        if (
          availableDatesString.includes(date.toString()) ||
          almostBookedDatesString.includes(date.toString())
        ) {
          delete notAvailableDates[notAvailableDates.indexOf(date)];
        }
      });
      setHighlightDates([
        { "react-datepicker__day--highlighted-custom-1": availableDates },
        { "react-datepicker__day--highlighted-custom-2": almostBookedDates },
      ]);
      getDates(
        moment().add(data?.minOnlineHours, "hours").format("YYYY-MM-DD"),
        moment().add(data?.maxOnlineHours, "hours").format("YYYY-MM-DD"),
        quotaDates,
        notAvailableDates?.filter((item) => item)
      );
      setSlotLoading(false);
    } catch (e) {
      ref.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });

      toast.error(e.message);
      if (e.message === "Session Expired") {
        logout();

        history?.push(
          `/${selectedlanguage}/devotee/signin?to=devotee-app/online-booking/pratyaksha-seva`
        );
      }
      setLoading(false);

      return;
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    let a = true;
    if (a) {
      let data = [...err];

      if (!checkbox) {
        data.push({
          type: "check",
          msg: "Please agree to terms and conditions",
        });
      }
      if (startDate === "") {
        data.push({ type: "date", msg: "Please select a date" });
      }
      if (slot === "") {
        data.push({ type: "slot", msg: "Please select a slot" });
      }
      if (gothram === "") {
        data.push({ type: "gothram", msg: "Please enter gothram" });
      }
      if (mobile.substring(0, 2) === "91") {
        if (mobile.length !== 12) {
          data.push({ type: "phone", msg: "Invalid mobile number" });
        }
      } else {
        if (mobile.length < 8) {
          data.push({ type: "phone", msg: "Invalid mobile number" });
        }
      }

      personStates.map((item) => {
        for (var key of Object.keys(item)) {
          if (item[key] === "") {
            if (key === "idProofType") {
              data.push({
                type: `${item["id"]}-${key}`,
                msg: `Select a id Proof`,
              });
            } else if (key === "idProofNumber") {
              data.push({
                type: `${item["id"]}-${key}`,
                msg: `Invalid id number`,
              });
            }
            // else if (key === "covid_file_url") {
            else {
              data.push({
                type: `${item["id"]}-${key}`,
                msg: `Invalid ${key}`,
              });
            }
          } else {
            if (key === "name") {
              if (item["name"].length < 3) {
                data.push({
                  type: `${item["id"]}-name`,
                  msg: "Minimun 3 characters",
                });
              }
            }
            // if (key === "gothram") {
            //   if (item["gothram"].length < 3) {
            //     data.push({
            //       type: `${item["id"]}-gothram`,
            //       msg: "Minimun 3 characters",
            //     });
            //   }
            // }
            if (key === "idProofType") {
              if (item["idProofNumber"] !== "") {
                if (item["idProofType"] === "pan") {
                  let txt = item["idProofNumber"].toUpperCase();
                  let regex =
                    /[a-zA-Z]{3}[PCHFATBLJG]{1}[a-zA-Z]{1}[0-9]{4}[a-zA-Z]{1}$/;
                  let pan = {
                    C: "Company",
                    P: "Personal",
                    H: "Hindu Undivided Family (HUF)",
                    F: "Firm",
                    A: "Association of Persons (AOP)",
                    T: "AOP (Trust)",
                    B: "Body of Individuals (BOI)",
                    L: "Local Authority",
                    J: "Artificial Juridical Person",
                    G: "Govt",
                  };
                  pan = pan[txt[3]];
                  if (regex.test(txt)) {
                    if (pan !== "Personal") {
                      data.push({
                        type: `${item["id"]}-idProofNumber`,
                        msg: "Invalid Pan Number",
                      });
                    }
                  } else
                    data.push({
                      type: `${item["id"]}-idProofNumber`,
                      msg: "Invalid Pan Number",
                    });
                } else if (item["idProofType"] === "ration") {
                  let regex = /^([a-zA-Z0-9]){8,12}\s*$/;
                  if (!regex.test(item["idProofNumber"].toUpperCase())) {
                    data.push({
                      type: `${item["id"]}-idProofNumber`,
                      msg: "Invalid ration number",
                    });
                  }
                } else if (item["idProofType"] === "driving") {
                  let regex =
                    /^(([A-Z]{2}[0-9]{2})( )|([A-Z]{2}-[0-9]{2}))((19|20)[0-9][0-9])[0-9]{7}$/;
                  if (!regex.test(item["idProofNumber"].toUpperCase())) {
                    // data.push({
                    //   type: `${item["id"]}-idProofNumber`,
                    //   msg: "Invalid driving license number",
                    // });
                  }
                } else if (item["idProofType"] === "voter") {
                  let regex = /^([a-zA-Z]){3}([0-9]){7}?$/;
                  if (!regex.test(item["idProofNumber"].toUpperCase())) {
                    data.push({
                      type: `${item["id"]}-idProofNumber`,
                      msg: "Invalid voter id",
                    });
                  }
                } else if (item["idProofType"] === "passport") {
                  let regex = /^[A-Za-z0-9]{6,15}$/;
                  if (!regex.test(item["idProofNumber"].toUpperCase())) {
                    data.push({
                      type: `${item["id"]}-idProofNumber`,
                      msg: "Invalid passport id",
                    });
                  }
                } else if (item["idProofType"] === "aadhar") {
                  let regex = /^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/;

                  if (!regex.test(item["idProofNumber"])) {
                    data.push({
                      type: `${item["id"]}-idProofNumber`,
                      msg: "Invalid AADHAAR number",
                    });
                  }
                }
              }
            }
          }
        }
      });
      setErr(data);
      if (data.length !== 0) {
        setLoading(false);
        ref.current?.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
        return;
      }
    }
    const checkDuplicate = checkDuplicateStrings(
      personStates.map((item) => {
        return item.idProofNumber;
      })
    );

    if (checkDuplicate) {
      setErr([
        {
          type: "idcheck",
          msg: "Make sure entered ID proofs are all unique.",
        },
      ]);
      setLoading(false);
      return;
    }

    try {
      setLoading(true);
      let dup = [...personStates];
      let arrayEx = dup.map((e) => {
        return {
          personName: e?.name,
          documentType: e.idProofType,
          documentId: e.idProofNumber,
        };
      });

      let schema = {
        cartContains: ["seva"],
        sevaBookings: [
          {
            sevaSlotId: slot?.productSlot?.id,
            bookingDate: moment(startDate).format("YYYY-MM-DD"),
            phoneNumber: `+${mobile}`,
            personDetails: arrayEx,
            gothram: gothram,
          },
        ],
        returnPriceStats: true,
      };
      if (addonsList?.length > 0) {
        addonsList?.map((item) => {
          if (!schema?.cartContains?.includes(item?.slot?.type)) {
            schema?.cartContains?.push(item?.slot?.type);
            if (item?.slot?.type === "darshanam") {
              schema.darshanamBookings = [];
            }
          }
          item?.devoteeList?.map((e) => {
            if (item?.slot?.type === "darshanam") {
              schema.darshanamBookings = [
                ...schema?.darshanamBookings,
                {
                  darshanamSlotId: item?.slot?.productSlot?.id,
                  bookingDate: moment(startDate).format("YYYY-MM-DD"),
                  phoneNumber: `+${mobile}`,
                  personDetails: [
                    {
                      personName: e?.name,
                      documentType: "aadhar",
                      documentId: e.idProofNumber,
                    },
                  ],
                },
              ];
            }
            if (item?.slot?.type === "seva") {
              schema.sevaBookings = [
                ...schema?.sevaBookings,
                {
                  sevaSlotId: item?.slot?.productSlot?.id,
                  bookingDate: moment(startDate).format("YYYY-MM-DD"),
                  phoneNumber: `+${mobile}`,
                  personDetails: [
                    {
                      personName: e?.name,
                      documentType: "aadhar",
                      documentId: e.idProofNumber,
                    },
                  ],
                },
              ];
            }
          });
        });
      }

      let res = await ParokshaSevaService.bookings(schema, templeID);
      if (res) {
        let data = {
          selectedArray,
          startDate,
          noPersons,
          personStates,
          slot,
          slotsData,
          mobile,
          res,
          gothram,
          templeID,
          addonIds,
          addonSlotIds,
          addonsList,
          addonsApiRes,
          addonSlotsData,
          selectedCard,
        };

        setcurrentBookingPratyaksha(data);
        history?.push(
          `/${selectedlanguage}/devotee-app/online-booking/pratyaksha-seva/confirm-details`
        );
      }
    } catch (e) {
      ref.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });

      toast.error(e.message);
      if (e.message === "Session Expired") {
        logout();

        history?.push(
          `/${selectedlanguage}/devotee/signin?to=devotee-app/online-booking/pratyaksha-seva`
        );
      }
      setLoading(false);

      return;
    }
  };
  //-------------------------------------------------------------functions-----------------------------------------------------------//

  return (
    <div className="col-xs-12 col-sm-12 col-md-9 col-lg-9 sd-dashboard">
      {loading ? (
        <Sd_loading />
      ) : (
        <div className="col-xs-12 col-sm-12 col-md-11 col-lg-11 pl-0 xs-pr-0 sd-profile sd-seva-form">
          <h2 className="sd-side-heading fw400">
            <span className="fw400">
              <span className="fw700" ref={ref}>
                Pratyaksha
              </span>{" "}
              Seva
            </span>
          </h2>
          <div className="clearfix">
            <form className="sd-form">
              <div className="form-group col-xs-12 col-sm-12 col-md-12 col-lg-12 xssm-pr-0 pl-0 pr-0">
                <label>
                  Select a Seva<span className="sd-imp">*</span>
                </label>

                <div className="select-div">
                  <div
                    className={
                      selectedArray.length > 0
                        ? "constant-top sd-white sd-voilet-border"
                        : "constant-top "
                    }
                  >
                    <div className="sd-buttonArray">
                      {buttonsArrayFunction()}
                    </div>

                    <div className="sd-input-image">
                      <input
                        className={selectedArray.length > 0 ? "sd-white" : " "}
                        type="text"
                        onClick={() => {
                          setShow(!show);
                        }}
                        onChange={() => {
                          // setSelectedValue({ value: e.target.value });
                          clearAddon();
                        }}
                      />
                      <img
                        className={show ? "sd-image-invert" : ""}
                        src={SD_Dropdown_Arrow_icon}
                        alt=""
                        onClick={() => {
                          setShow(!show);
                        }}
                      />
                    </div>
                  </div>

                  {show && (
                    <div className="option-div" style={{ paddingTop: "1vh" }}>
                      {listFunctionSevas()}
                    </div>
                  )}
                </div>
                {selectedArray.find(
                  (e) =>
                    e?.name ===
                    "Abhishekam Alamkaramandapam (Couple) Only Alamkara Darshanam"
                ) && (
                  <p style={{ color: "red" }}>
                    Note : Due to devotee rush on going in this Karthika Masam,
                    devotees are allowed to make Alankara Dharshanam only.
                  </p>
                )}
                {selectedArray.find(
                  (e) => e?.name === "Samuhika Abhishekamulu (Couple)"
                ) && (
                  <p style={{ color: "red" }}>
                    Note : Kind attention Devotees. Due to Heavy Rush during
                    Kartheeka Masam, the Sparsha Darsanam of Sri Mallikarjuna
                    Swamy and Garbhalaya Abhishekams are being stopped on
                    Sundays, Mondays and Kartheeka Pournami from 05.11.2021 to
                    04.12.2021.
                  </p>
                )}
                {selectedArray.find(
                  (e) => e?.name === "VIP Break Darshanam"
                ) && (
                  <p style={{ color: "red" }}>
                    Note : Kind attention Devotees. Due to Heavy Rush during
                    Kartheeka Masam, the Sparsha Darsanam Tickets of Sri
                    Mallikarjuna Swamy and Garbhalaya Abhishekams Tickets are
                    freezed on Sundays, Mondays and Kartheeka Pournami from
                    05.11.2021 to 04.12.2021.
                  </p>
                )}
              </div>
              <hr className="clearfix"></hr>
              <div
                className="sd-profile"
                style={{ display: "flex", flexWrap: "wrap" }}
              >
                <div className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6 xssm-pr-0 pl-0">
                  <label>
                    Select Date<span className="sd-imp">*</span>
                  </label>

                  <div
                    className={
                      err?.find((e) => e.type === `date`)
                        ? "sd-dob customDatePickerWidth sd-red"
                        : startDate !== ""
                        ? "sd-date-voilet sd-dob customDatePickerWidth"
                        : "sd-dob customDatePickerWidth"
                    }
                  >
                    <DatePicker
                      allowSameDay={false}
                      placeholderText="DD/MM/YYYY"
                      dateFormat={"dd/MM/yyyy"}
                      showDisabledMonthNavigation
                      style={{
                        width: "90%",
                        border: "none",
                      }}
                      minDate={addDays(
                        new Date(),
                        selectedArray[0]?.minOnlineHours / 24
                      )}
                      maxDate={addDays(
                        new Date(),
                        selectedArray[0]?.maxOnlineHours / 24
                      )}
                      highlightDates={highlightDates}
                      excludeDates={excludeDates}
                      clearIcon={null}
                      selected={startDate}
                      onChange={(date) => {
                        clearAddon();
                        if (err.find((e) => e.type === "date")) {
                          let data = err.filter((e) => e.type !== "date");
                          setErr(data);
                        }
                        setStartDate(date);
                        getAddon(selectedArray[0], date);
                      }}
                    >
                      <div className="sd-quota">
                        <div className="sd-quota-div">
                          <p
                            style={{
                              backgroundColor: "rgb(0, 207, 0)",
                              color: "rgb(0, 207, 0)",
                            }}
                          ></p>
                          Available
                        </div>
                        <div className="sd-quota-div">
                          <p
                            style={{
                              backgroundColor: "orange",
                              color: "orange",
                            }}
                          ></p>
                          Almost Booked
                        </div>
                      </div>
                      <div className="sd-quota">
                        <div className="sd-quota-div">
                          <p
                            style={{
                              backgroundColor: "rgb(201, 0, 0)",
                              color: "rgb(201, 0, 0)",
                            }}
                          ></p>
                          Not Available
                        </div>
                      </div>
                    </DatePicker>
                  </div>
                  <p className="sd-EM mr-15">
                    {err.find((e) => e.type === "date")?.msg}
                  </p>
                </div>
                <div className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6  xssm-pl-0 xssm-pr-0  ">
                  <label>
                    Number of Person(s)<span className="sd-imp">*</span>
                  </label>
                  <select
                    className={
                      noPersons !== ""
                        ? "form-control sd-voilet"
                        : "form-control"
                    }
                    value={noPersons}
                    onChange={(e) => {
                      if (
                        selectedArray[0]?.name ===
                          "Samuhika Abhishekamulu (Single)" ||
                        selectedArray[0]?.name ===
                          "Samuhika Abhishekamulu (Couple)" ||
                        selectedArray[0]?.name === "VIP Break Darshanam"
                      ) {
                        if (
                          selectedArray[0]?.name ===
                            "Samuhika Abhishekamulu (Single)" ||
                          selectedArray[0]?.name === "VIP Break Darshanam"
                        ) {
                          setNoperons(1);
                        } else if (
                          selectedArray[0]?.name ===
                          "Samuhika Abhishekamulu (Couple)"
                        ) {
                          setNoperons(2);
                        }
                      } else {
                        setNoperons(e.target.value);
                      }
                    }}
                  >
                    {countArray.map((num) => {
                      return (
                        <option key={num} value={num}>
                          {num}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6 xssm-pl-0 xssm-pr-0 pl-0   mb-0">
                  <label>
                    Gothram<span className="sd-imp">*</span>
                  </label>
                  <input
                    maxLength="30"
                    type="text"
                    placeholder="Enter gothram."
                    className={
                      err?.find((e) => e.type === `gothram`)
                        ? "form-control sd-red"
                        : gothram !== ""
                        ? "form-control sd-voilet"
                        : "form-control"
                    }
                    value={gothram}
                    onChange={(e) => {
                      if (
                        /^[a-zA-Z ]*$/.test(String(e.target.value)) ||
                        e.target.value === ""
                      ) {
                        if (err.find((e) => e.type === `gothram`)) {
                          let data = err.filter((e) => e.type !== `gothram`);
                          setErr(data);
                        }
                        setGothram(e.target.value);
                      }
                    }}
                  />
                  <p className="sd-EM ">
                    {err.find((e) => e.type === `gothram`)?.msg}
                  </p>
                </div>
                <div className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6  xssm-pl-0 xssm-pr-0 pr-0">
                  <label>
                    Mobile Number<span className="sd-imp">*</span>
                  </label>
                  <div className={"sd-profile-mobile"}>
                    <PhoneInput
                      inputExtraProps={{
                        autoFocus: true,
                        enableSearch: true,
                        countryCodeEditable: false,
                      }}
                      onlyCountries={["in"]}
                      placeholder="Enter Your 10 digit mobile number"
                      maxLength="15"
                      countryCodeEditable={false}
                      containerClass={
                        err?.find((e) => e.type === "phone")
                          ? "sd-phone-voilet sd-red"
                          : mobile !== ""
                          ? "sd-phone-voilet"
                          : ""
                      }
                      country="in"
                      value={mobile}
                      onChange={(e) => {
                        if (
                          err?.find(
                            (e) => e.type === "phone" || e.type === "common"
                          )
                        ) {
                          let array = err.filter((e) => {
                            if (e.type !== "phone" && e.type !== "common") {
                              return e;
                            }
                          });
                          setErr(array);
                        }
                        setMobile(e);
                      }}
                    />
                    <p className="d-EM ">
                      {err.find((e) => e.type === "phone")
                        ? err.find((e) => e.type === "phone")?.msg
                        : ""}
                    </p>
                  </div>
                </div>
                <p>Free for Children under 10 years</p>
              </div>
              {slotsData?.length !== 0 && startDate ? (
                <>
                  <hr className="clearfix"></hr>

                  <div
                    className="clearfix sd-darshanam-content"
                    style={{ position: "relative" }}
                  >
                    <br />
                    {getSlotsDetails()}
                    <p
                      style={{
                        position: "absolute",
                        top: "-12px",
                        left: "0px",
                        textAlign: "right",
                        color: "red",
                      }}
                    >
                      {err?.find((e) => e.type === `slot`)?.msg}
                    </p>
                  </div>
                </>
              ) : (
                <>
                  {startDate && !slotLoading ? (
                    <>
                      <hr className="clearfix"></hr>{" "}
                      <div
                        className="clearfix sd-darshanam-content"
                        style={{ position: "relative", color: "red" }}
                      >
                        No Slots available on{" "}
                        {moment(startDate).format("DD-MM-YYYY")}
                      </div>
                    </>
                  ) : (
                    startDate && (
                      <>
                        <hr className="clearfix"></hr>{" "}
                        <div
                          className="clearfix sd-darshanam-content"
                          style={{ position: "relative" }}
                        >
                          Fetching slots...Please wait{" "}
                        </div>
                      </>
                    )
                  )}
                </>
              )}
              <div className="clearfix">
                <hr className="clearfix"></hr>
              </div>{" "}
              {personStates.map((item, i) => {
                return (
                  <>
                    {" "}
                    <div className="clearfix" key={i + "personstates-prs"}>
                      <div
                        className="form-group col-xs-12 col-sm-12 col-md-1 col-lg-1  xssm-pr-0 pl-0 ml-0 mr-0"
                        style={{
                          width: "2vw",
                        }}
                      >
                        <label>{i + 1}.</label>
                      </div>
                      <div className="form-group col-xs-12 col-sm-12 col-md-11 col-lg-11  xssm-pl-0 pr-0">
                        <div className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-4 xssm-pr-0 pl-0">
                          <label>
                            Name<span className="sd-imp">*</span>
                          </label>
                          <input
                            maxLength="20"
                            autoComplete="new-password"
                            onFocus={() => {
                              if (autoFill === `${i}autofill`) {
                                setAutoFill("none");
                              } else {
                                setAutoFill(`${i}autofill`);
                              }
                            }}
                            onBlur={() => {
                              if (autoFill === `${i}autofill`) {
                                setTimeout(() => {
                                  setAutoFill("none");
                                }, 250);
                              }
                            }}
                            placeholder="Please enter your name"
                            type="text"
                            className={
                              err?.find((e) => e.type === `${i + 1}-name`)
                                ? "form-control sd-red"
                                : item?.name !== ""
                                ? "form-control sd-voilet"
                                : "form-control"
                            }
                            value={item?.name}
                            onChange={(e) => {
                              if (
                                /^[a-zA-Z ]*$/.test(String(e.target.value)) ||
                                e.target.value === ""
                              ) {
                                if (
                                  err.find((e) => e.type === `${i + 1}-name`)
                                ) {
                                  let data = err.filter(
                                    (e) => e.type !== `${i + 1}-name`
                                  );
                                  setErr(data);
                                }
                                handleChange(i, e, "name");
                              }
                            }}
                          />
                          <p className="sd-EM mr-15 ">
                            {err.find((e) => e.type === `${i + 1}-name`)?.msg}
                          </p>
                          {autoFill === `${i}autofill` &&
                            autoFillFunction(item?.name, i)}
                        </div>
                        <div className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-4 xssm-pr-0">
                          <label>
                            Photo ID Proof<span className="sd-imp">*</span>
                          </label>
                          <select
                            className={
                              err?.find(
                                (e) => e.type === `${i + 1}-idProofType`
                              )
                                ? "form-control sd-red"
                                : item.idProofType !== ""
                                ? "form-control sd-voilet"
                                : "form-control"
                            }
                            value={item?.idProofType}
                            onChange={(e) => {
                              if (
                                err.find(
                                  (e) => e.type === `${i + 1}-idProofType`
                                )
                              ) {
                                let data = err.filter(
                                  (e) => e.type !== `${i + 1}-idProofType`
                                );
                                setErr(data);
                              }

                              handleChange(i, e, "idProofType");
                            }}
                          >
                            <option style={{ display: "none" }}>
                              Select a valid id proof
                            </option>

                            <option key="pan" value="pan">
                              PAN
                            </option>
                            <option key="aadhar" value="aadhar">
                              AADHAAR
                            </option>
                            <option key="passport" value="passport">
                              Passport
                            </option>
                            <option key="driving" value="driving">
                              Driving License
                            </option>
                            <option key="ration" value="ration">
                              Ration Card
                            </option>
                            <option key="voter" value="voter">
                              Voter ID
                            </option>
                          </select>
                          <p className="sd-EM mr-15">
                            {
                              err.find((e) => e.type === `${i + 1}-idProofType`)
                                ?.msg
                            }
                          </p>
                        </div>
                        <div className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-4 xssm-pl-0 xssm-pr-0 ">
                          <label>
                            Photo ID Number<span className="sd-imp">*</span>
                          </label>

                          <input
                            placeholder="Please enter your id number"
                            type="text"
                            className={
                              err?.find(
                                (e) => e.type === `${i + 1}-idProofNumber`
                              )
                                ? "form-control sd-red"
                                : item?.idProofNumber !== ""
                                ? "form-control sd-voilet"
                                : "form-control"
                            }
                            value={item?.idProofNumber}
                            style={{ textTransform: "none" }}
                            onChange={(e) => {
                              if (
                                /^[#.0-9a-zA-Z\s]+$/.test(e.target.value) ||
                                e.target.value === ""
                              ) {
                                if (
                                  err.find(
                                    (e) =>
                                      e.type === `${i + 1}-idProofNumber` ||
                                      e.type === "idcheck"
                                  )
                                ) {
                                  let data = err.filter(
                                    (e) =>
                                      e.type !== `${i + 1}-idProofNumber` &&
                                      e.type !== "idcheck"
                                  );
                                  setErr(data);
                                }
                                handleChange(i, e, "idProofNumber");
                              }
                            }}
                          />
                          <p className="sd-EM mr-15 ">
                            {
                              err.find(
                                (e) => e.type === `${i + 1}-idProofNumber`
                              )?.msg
                            }
                          </p>
                        </div>
                      </div>
                    </div>
                    <hr className="clearfix"></hr>
                  </>
                );
              })}
              {addonsApiRes?.length > 0 && (
                <Addons selectedTemple={templeID} startDate={startDate} />
              )}
              <div className="form-group col-xs-12 col-sm-12 col-md-12 col-lg-12  xssm-pl-0 pr-0">
                <p
                  className="ml--15"
                  style={{
                    color: "#181818",
                    fontWeight: "600",

                    whiteSpace: "pre-wrap",
                  }}
                >
                  Note: Pilgrims who book for Pratyaksha Seva should bring the
                  printed copy of their receipt.{" "}
                </p>
                <ol className="sd-terms-list">
                  {termsList.map((item, i) => {
                    return (
                      <li className="active" key={i + "terms-prs"}>
                        <a style={{ paddingRight: "3px" }}>{item}</a>
                      </li>
                    );
                  })}
                  <li>
                    Only single person will be allowed to perform Single
                    Abhishekam ticket on one ticket{" "}
                    {selectedArray[0]?.name ===
                    "Samuhika Abhishekamulu (Single)"
                      ? `(Couples are not allowed)`
                      : ""}
                    .
                  </li>
                  <li>
                    Devotees must report just 15 minutes prior the Seva timings
                    at the ritual spot.
                  </li>
                </ol>

                <div
                  className="form-group col-xs-12 col-sm-12 col-md-12 col-lg-12 xssm-pr-0 pl-0 sd-terms-checkbox"
                  style={{ alignItems: "center" }}
                >
                  {" "}
                  <input
                    className="mr-5"
                    type="checkbox"
                    name=""
                    value={checkbox}
                    onChange={() => {
                      if (err.find((e) => e.type === `check`)) {
                        let data = err.filter((e) => e.type !== `check`);
                        setErr(data);
                      }
                      setCheckbox(!checkbox);
                    }}
                    checked={checkbox === true ? true : false}
                  />
                  <label
                    htmlFor=""
                    style={{ marginBottom: "0px", position: "relative" }}
                  >
                    I agree to the{" "}
                    <a
                      onClick={() => {
                        history?.push(
                          `/${selectedlanguage}/legalities/terms-and-conditions`
                        );
                      }}
                    >
                      Terms & Conditions
                    </a>{" "}
                    and the
                    <a
                      onClick={() => {
                        history?.push(
                          `/${selectedlanguage}/legalities/privacy-policy`
                        );
                      }}
                    >
                      {" "}
                      Privacy Policy
                    </a>
                  </label>
                  <p className="sd-accept">
                    {err.find((e) => e.type === "check")?.msg}
                  </p>
                </div>
              </div>
              <hr className="clearfix"></hr>
              <div className="clearfix mt-50">
                <a
                  className="sd-btn-orange"
                  onClick={() => {
                    if (!loading) {
                      handleSubmit();
                    }
                  }}
                >
                  Confirm Booking{" "}
                  {loading ? (
                    <img
                      src={sd_btn_loading}
                      alt=".."
                      className="sd-arrow-btn ml-5 h-20"
                    />
                  ) : (
                    <img
                      className="sd-arrow-btn ml-5"
                      src={SD_Right_Arrow_white}
                    />
                  )}
                </a>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};
export default Sd_dashboard_pratyakshaseva_seva_details;
