import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import { withRouter } from "react-router";
import { toast } from "react-toastify";
import {
  sd_dashboard_edit_ico,
  sd_dashboard_side_arrow,
  sd_failure_icon,
  sd_success_icon,
} from "../../../../Assets/imageList";
import Sd_loading from "../../../../Components/sd-common-components/sd-loading";
import { SD_Context } from "../../../../context/sd-context";
import Sd_DashboardService from "../../../../services/sd-dashboard.service";
import ParokshaSevaService from "../../../../services/sd-parokshaseva.service";
import DisplayRazorpay from "../../../../Utils/sd-razorpay/sd-razorpay-display";
import { BASE_BANK } from "../../../../Utils/sd-axios/sd-api-url";

const Sd_dashboard_online_booking_publications_confirm = ({ history }) => {
  //-------------------------------------------------------------states-----------------------------------------------------------//

  const { setActive, setActivePath, cbSrisailaPrabha, userData } =
    useContext(SD_Context);
  const supportedLanguages = ["en-in", "jp-in", "de-in", "es-in"];
  const [selectedLanguage, setSelectedlanguage] = React.useState("en-in");
  const [loading, setloading] = useState(true);
  const [successOrfailureFlag, setSuccessOrfailureFlag] = useState(false);
  const [status, setStatus] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [dissmissed, setDissmissed] = useState("");
  const [halfState, setHalfState] = useState(null);
  const [charges, setCharges] = useState("");
  // const [result, setResult] = useState({});
  const refContainer = useRef();
  const [userSelectedObj, setUserSelectedObj] = useState({});
  const [timestamp, setTimestamp] = useState("");

  const Months = [
    "Jan",
    "Feb",
    "March",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  //-------------------------------------------------------------states-----------------------------------------------------------//

  //-------------------------------------------------------------useeffects-----------------------------------------------------------//

  useEffect(async () => {
    setActive("publications-confirm");
    setActivePath("Onlinebooking-Publications-Confirm");
    if (
      supportedLanguages.includes(
        history?.location?.pathname?.split?.("/")?.[1]
      )
    ) {
      setSelectedlanguage(history?.location?.pathname?.split?.("/")?.[1]);
    } else {
      setSelectedlanguage("en-in");
    }
    if (!cbSrisailaPrabha) {
      history?.push(
        `/${selectedLanguage}/devotee/signin?to=devotee-app/online-booking/publications`
      );
    }
    if (cbSrisailaPrabha?.subscription === "subscription") {
      let charges = await Sd_DashboardService.get_taxes(
        cbSrisailaPrabha?.subType?.price
      );
      setCharges(charges);
    } else if (cbSrisailaPrabha?.subscription === "download") {
      let totalAmount = 0;
      cbSrisailaPrabha?.selectedMonthsArray.map((item) => {
        totalAmount += item?.price;
      });
      let charges = await Sd_DashboardService.get_taxes(
        totalAmount,
        userData?.phone.substring(0, 3) === "+91" ? "INR" : "USD"
      );
      setCharges(charges);
    } else if (cbSrisailaPrabha?.subscription === "purchase") {
      let selectedObj;
      cbSrisailaPrabha?.selected?.sd_publication_file?.map((item) => {
        if (
          item?.type === cbSrisailaPrabha?.subscription &&
          item?.language_options?.includes(String(cbSrisailaPrabha?.lang))
        ) {
          selectedObj = item;
        }
      });
      setUserSelectedObj(selectedObj);
      let charges = await Sd_DashboardService.get_taxes(
        selectedObj?.price + selectedObj?.delivery_price
      );
      setCharges(charges);
    }

    setTimestamp("");
    setloading(false);
    return () => {
      setTimestamp("");
    };
  }, []);

  useEffect(() => {
    if (dissmissed === "failed") {
      setErrorMsg("Cancelled by User");
      setSuccessOrfailureFlag(true);
      setStatus("failed");
      setloading(false);
      setDissmissed("");
    }
  }, [dissmissed]);

  useEffect(() => {
    refContainer?.current?.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  }, [status]);

  //-------------------------------------------------------------useeffects-----------------------------------------------------------//

  //-------------------------------------------------------------functions-----------------------------------------------------------//
  function capitalize(str) {
    let splitStr = str?.toLowerCase?.().split?.(" ");
    for (let i = 0; i < splitStr?.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr?.join(" ");
  }

  const handleSubmit = async () => {
    let res;
    let finalSchema;
    if (!halfState) {
      try {
        if (cbSrisailaPrabha?.subscription === "subscription") {
          let local = {
            publicationBooking: {
              full_name: `${capitalize(cbSrisailaPrabha?.fname)} ${capitalize(
                cbSrisailaPrabha?.lname
              )}`,
              address: capitalize(cbSrisailaPrabha?.address1),
              contact: `+${cbSrisailaPrabha?.mobile}`,
              pin_code: cbSrisailaPrabha?.zip,
              state: cbSrisailaPrabha?.state,
              email: cbSrisailaPrabha?.email,
              city: capitalize(cbSrisailaPrabha?.city),
              country: cbSrisailaPrabha?.country,
              language: cbSrisailaPrabha?.lang,
            },
            itemType: "publication",
            itemId: cbSrisailaPrabha?.subType.id,
          };
          if (cbSrisailaPrabha?.address2 !== "") {
            local.publicationBooking.address2 = capitalize(
              cbSrisailaPrabha?.address2
            );
          }
          finalSchema = [local];
        } else if (cbSrisailaPrabha?.subscription === "download") {
          let local = [];
          cbSrisailaPrabha?.selectedMonthsArray.map((item) => {
            local.push({
              publicationBooking: {
                full_name: `${capitalize(cbSrisailaPrabha?.fname)} ${capitalize(
                  cbSrisailaPrabha?.lname
                )}`,
                contact: `+${cbSrisailaPrabha?.mobile}`,
                email: cbSrisailaPrabha?.email,
                language: cbSrisailaPrabha?.lang,
              },
              itemType: "publication",
              itemId: item.id,
            });
          });
          finalSchema = local;
        } else if (cbSrisailaPrabha?.subscription === "purchase") {
          // let selectedObj;
          // cbSrisailaPrabha?.selected?.sd_publication_file?.map((item) => {
          //   if (
          //     item?.type === cbSrisailaPrabha.subscription &&
          //     item?.language_options?.includes(String(cbSrisailaPrabha?.lang))
          //   ) {
          //     selectedObj = item;
          //   }
          // });
          let local = {
            publicationBooking: {
              full_name: `${capitalize(cbSrisailaPrabha?.fname)} ${capitalize(
                cbSrisailaPrabha?.lname
              )}`,
              address: capitalize(cbSrisailaPrabha?.address1),
              contact: `+${cbSrisailaPrabha?.mobile}`,
              pin_code: cbSrisailaPrabha?.zip,
              state: cbSrisailaPrabha?.state,
              email: cbSrisailaPrabha?.email,
              city: capitalize(cbSrisailaPrabha?.city),
              country: cbSrisailaPrabha?.country,
              language: cbSrisailaPrabha?.lang,
            },
            itemType: "publication",
            itemId: userSelectedObj.id,
          };
          if (cbSrisailaPrabha?.address2 !== "") {
            local.publicationBooking.address2 = capitalize(
              cbSrisailaPrabha?.address2
            );
          }
          finalSchema = [local];
        }

        // res = await ParokshaSevaService.bookings(finalSchema, BASE_BANK, false);
        res = await ParokshaSevaService.bookings(
          finalSchema,
          BASE_BANK,
          false,
          userData?.phone.substring(0, 3) === "+91" ? "INR" : "USD"
        );
        setHalfState(res);
        // setResult(res);
      } catch (e) {
        toast.error(e.message);
        if (e.message === "Session Expired") {
          history?.push(
            `/${selectedLanguage}/devotee/signin?to=devotee-app/online-booking/publications?shift=true`
          );
        }
        setloading(false);
        return;
      }
    }

    try {
      if (halfState !== null) {
        if (Math.floor((Date.now() - timestamp) / 1000) > 900) {
          toast.error(
            "Redirecting to Previous Page as buffer time of 15 min for retrying the payment has been exceeded."
          );
          setTimestamp("");
          setTimeout(() => {
            history?.push(
              `/${selectedLanguage}/devotee-app/online-booking/paroksha-seva/booking/ganapathi-homam`
            );
          }, 3000);
          return;
        }
        await DisplayRazorpay(
          halfState?.paymentResp?.razorResp?.id,
          halfState?.paymentResp?.razorResp?.currency,
          halfState?.paymentResp?.razorResp?.amount.toString(),
          halfState?.cart,
          BASE_BANK,
          setDissmissed,
          userData,
          dissmissed
        );
        // console.log(result);
        setHalfState(null);
        setSuccessOrfailureFlag(true);
        setStatus("success");
        setTimestamp("");

        setloading(false);
        if (cbSrisailaPrabha?.subscription === "download") {
          toast.success("Your download will start in a few seconds.");

          setTimeout(() => {
            downloadPDF(halfState);
          }, 4000);
        }
      } else {
        setTimestamp(Date.now());

        await DisplayRazorpay(
          res?.paymentResp?.razorResp?.id,
          res?.paymentResp?.razorResp?.currency,
          res?.paymentResp?.razorResp?.amount.toString(),
          res?.cart,
          BASE_BANK,
          setDissmissed,
          userData,
          dissmissed
        );
        // console.log(result);
        setHalfState(null);
        setSuccessOrfailureFlag(true);
        setTimestamp("");
        setStatus("success");
        setloading(false);
        toast.success("Your download will start in a few seconds.");

        if (cbSrisailaPrabha?.subscription === "download") {
          setTimeout(() => {
            downloadPDF(res);
          }, 4000);
        }
      }
    } catch (e) {
      // console.log(e);
      setErrorMsg(e.message);
      setSuccessOrfailureFlag(true);
      setStatus("failed");
      setloading(false);
    }
  };
  const getStatusFunction = () => {
    switch (status) {
      case "success":
        return (
          <div className="sd-success" ref={refContainer}>
            <div className="sd-success-top sd-green">
              <img src={sd_success_icon} alt="success" /> <p>Success</p>
            </div>
            <div className="sd-success-bottom">
              <h3>
                {cbSrisailaPrabha?.subscription === "subscription" ? (
                  `Your Subscription to ${cbSrisailaPrabha?.selected?.name}  is successful.`
                ) : cbSrisailaPrabha?.subscription === "download" ? (
                  `Your request to download ${cbSrisailaPrabha?.selected?.name}  ${cbSrisailaPrabha?.lang} edition has been made successfully. The file link will be sent via SMS or can be downloaded from booking history page.`
                ) : cbSrisailaPrabha?.subscription === "purchase" ? (
                  <>
                    Purchase successful! You have successfully purchased
                    <span className="tt ml-3 mr-3">
                      {cbSrisailaPrabha?.lang}{" "}
                    </span>
                    {cbSrisailaPrabha?.selected?.name} on
                    {` ${moment(new Date()).format("DD-MM-YYYY")}`} by paying an
                    amount of{" "}
                    {userData?.phone.substring(0, 3) === "+91" ? "Rs." : "$"}
                    {parseFloat(charges?.totalAmount).toFixed(2)}.
                  </>
                ) : (
                  ""
                )}
              </h3>
              <div className="sd-success-bottom-down">
                <p
                  className="sd-border-right"
                  onClick={() => {
                    history?.push(
                      `/${selectedLanguage}/devotee-app/booking-history`
                    );
                  }}
                >
                  Booking History
                </p>{" "}
                <p
                  onClick={() => {
                    history?.push(`/${selectedLanguage}/home`);
                  }}
                >
                  Go to Home
                </p>
              </div>
            </div>
          </div>
        );
      case "failed":
        return (
          <div className="sd-success" ref={refContainer}>
            <div className="sd-success-top sd-redback">
              <img src={sd_failure_icon} alt="success" />{" "}
              <p>Sorry, your Subscription failed!. {errorMsg} </p>
            </div>
            <div className="sd-success-bottom">
              <h3>
                <>
                  <span className="tt ml-2 mr-3">
                    {cbSrisailaPrabha?.subscription}
                  </span>
                  of{" "}
                  <span className="tt ml-2 mr-3">
                    {cbSrisailaPrabha?.selected?.name}
                  </span>
                  failed! An error has occurred. Check your booking history or
                  SMS after 20 mins for confirmation. Please note that any funds
                  debited will credit back to your account within 7 working
                  days.
                </>
              </h3>
              <div className="sd-success-bottom-down">
                <p
                  className="sd-border-right"
                  onClick={() => {
                    setStatus("");
                    setSuccessOrfailureFlag(false);
                  }}
                >
                  Try Again
                </p>
                <p
                  onClick={() => {
                    history?.push(`/${selectedLanguage}/support/contactUs`);
                  }}
                >
                  Contact Us
                </p>
              </div>
            </div>
          </div>
        );
    }
  };

  const downloadPDF = async (res) => {
    try {
      res.ticketIds?.map(async (item) => {
        let data = await Sd_DashboardService.download_publication(item);

        try {
          let url = window?.URL?.createObjectURL(data?.data);
          let a = document.createElement("a");
          a.href = url;
          a.download = `${data.headers["publication_file_name"]}
             .pdf`;
          a.click();
        } catch (E) {
          // console.log("object");
        }
      });
    } catch (e) {
      toast.error(e?.message);
      // console.log(object);
    }

    // try {
    //   for (let i = 0; i < res.ticketIds?.length; i++) {
    //     Sd_DashboardService.download_publication(res.ticketIds[i]).then(
    //       (response) => {
    //         let url = window.URL.createObjectURL(response);
    //         let a = document.createElement("a");
    //         a.href = url;
    //         a.download = `${cbSrisailaPrabha?.selected?.name}-${
    //           cbSrisailaPrabha?.subscription
    //         }-${moment().format("DD-MM-YYYY HH:MM")}.pdf`;
    //         a.click();
    //         // });
    //       }
    //     );
    //   }
    // } catch (e) {
    //   toast.error(e?.message);
    // }
  };
  //-------------------------------------------------------------functions-----------------------------------------------------------//
  return (
    <div
      className="col-xs-12 col-sm-12 col-md-9 col-lg-9 sd-dashboard"
      style={{
        minHeight: document.getElementById("side-menu")?.offsetHeight,
      }}
    >
      {loading ? (
        <Sd_loading />
      ) : successOrfailureFlag ? (
        getStatusFunction()
      ) : (
        <div className="sd-confirm-details">
          <h2 className="sd-side-heading fw400">
            <span className="fw400">
              <span className="fw700">Confirm</span> Details
            </span>
            <a
              onClick={() => {
                history?.push(
                  `/${selectedLanguage}/devotee-app/online-booking/publications?to=${cbSrisailaPrabha?.selected?.name?.replaceAll?.(
                    " ",
                    "-"
                  )}`
                );
              }}
              className="sd-confirm-edit-button"
            >
              <img src={sd_dashboard_edit_ico} /> Edit details
            </a>
          </h2>
          <div className="clearfix  sd-booking-details">
            <h4>Booking Details</h4>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th width="40%">Subscription for</th>
                  <th width="30">Language</th>
                  <th width="30%">Price</th>
                  {/* <th width="20%">Total Price</th> */}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      paddingLeft: "10px !important",
                    }}
                  >
                    {cbSrisailaPrabha?.subscription === "subscription" && (
                      <span>
                        {" "}
                        {cbSrisailaPrabha?.selected?.name} <br />
                        Subscription - {cbSrisailaPrabha?.subType?.name}
                      </span>
                    )}
                    {cbSrisailaPrabha?.subscription === "download" && (
                      <span>
                        {cbSrisailaPrabha?.selected?.name} - (Download)
                        <br />
                        <br />
                        {cbSrisailaPrabha?.selectedMonthsArray.map(
                          (item, i) => {
                            return (
                              <>
                                <span key={i + "months-pub"}>
                                  {item?.year} - {Months[item?.month]}{" "}
                                </span>
                                <br />
                              </>
                            );
                          }
                        )}
                      </span>
                    )}
                    {cbSrisailaPrabha?.subscription === "purchase" && (
                      <span>
                        {" "}
                        {cbSrisailaPrabha?.selected?.name} - (Purchase)
                        <br />{" "}
                      </span>
                    )}
                  </td>
                  <td>
                    {" "}
                    {cbSrisailaPrabha?.lang && (
                      <span style={{ textTransform: "capitalize" }}>
                        {cbSrisailaPrabha?.lang}
                      </span>
                    )}
                  </td>
                  <td>
                    {userData?.phone.substring(0, 3) === "+91" ? "Rs." : "$"}{" "}
                    {parseFloat(charges?.totalAmount).toFixed(2)}
                    <br />
                    {cbSrisailaPrabha?.subscription === "purchase" ? (
                      <>
                        (Delivery charges:{" "}
                        {userData?.phone.substring(0, 3) === "+91"
                          ? "Rs."
                          : "$"}{" "}
                        {parseFloat(userSelectedObj?.delivery_price).toFixed(2)}
                        )
                      </>
                    ) : (
                      ""
                    )}
                    <br /> (Handling charges:{" "}
                    {userData?.phone.substring(0, 3) === "+91" ? "Rs." : "$"}{" "}
                    {parseFloat(charges?.additionalAmount).toFixed(2)})
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="clearfix sd-devotee-details mt-30">
            <h4>Devotee Details</h4>
            <table className="table table-bordered">
              <thead>
                <tr>
                  {cbSrisailaPrabha?.subscription === "download" ? (
                    <>
                      <th width="5%">S.No.</th>
                      <th width="30%">Name</th>
                      <th width="35%">Mobile</th>
                      <th width="30%">Email</th>
                    </>
                  ) : (
                    <>
                      <th width="5%">S.No.</th>
                      <th width="20%">Name</th>
                      <th width="25%">Mobile</th>
                      <th width="20%">Email</th>
                      <th width="20%"> Address</th>
                    </>
                  )}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1.</td>
                  <td style={{ textTransform: "capitalize" }}>
                    {cbSrisailaPrabha?.fname} {cbSrisailaPrabha?.lname}
                  </td>
                  <td>+{cbSrisailaPrabha?.mobile}</td>
                  <td style={{ textTransform: "none" }}>
                    {cbSrisailaPrabha?.email}
                  </td>
                  {cbSrisailaPrabha?.subscription !== "download" && (
                    <>
                      <td>
                        {cbSrisailaPrabha?.address1}.
                        {cbSrisailaPrabha?.address2}
                        <br />
                        {cbSrisailaPrabha?.city},{cbSrisailaPrabha?.state},
                        {cbSrisailaPrabha?.country}
                      </td>
                    </>
                  )}
                </tr>
              </tbody>
            </table>
          </div>
          <div className="clearfix mt-50 sd-form">
            <a
              className="sd-btn-orange"
              onClick={() => {
                if (!loading) {
                  handleSubmit();
                }
              }}
            >
              Proceed to Payment{" "}
              <img
                className="sd-arrow-btn ml-5"
                src={sd_dashboard_side_arrow}
              />
            </a>
          </div>
        </div>
      )}{" "}
    </div>
  );
};
export default withRouter(Sd_dashboard_online_booking_publications_confirm);
