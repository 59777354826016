/* eslint-disable no-async-promise-executor */

import { API_URL } from "../sd-axios/sd-api-url";

async function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

async function DisplayRazorpay(
  order_id,
  currency,
  amount,
  cart,
  onlinePaymentId,
  vendorType,
  setDissmissed,
  userData,
  dissmissed,
  razorKey,
  templeID
) {
  // console.log(userData?.email);
  return new Promise(async (resolve, reject) => {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );
    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }
    try {
      const options = {
        key: razorKey,
        currency: currency,
        amount: amount.toString(),
        order_id: order_id,
        modal: {
          ondismiss: async () => {
            // console.log("dismissed");
            let canceledData = await fetch(
              `${API_URL}razorpay/razorpay/canceled/${onlinePaymentId}`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${localStorage.getItem(
                    "accessToken"
                  )}`,
                  "X-Temple-Code": templeID,
                },
                body: JSON.stringify({
                  orderID: order_id,
                }),
              }
            );
            setDissmissed("failed");
            await canceledData.json();
            // console.log(dissmissed);
          },
          onhidden: () => {
            // console.log("hidden");
            // setDissmissed("failed");
          },
        },
        theme: { color: "#991403" },
        name: "Sri Kalahastheeswara Swamy Vari Devasthanam",
        // description: "Thank you for nothing. Please give us some money",
        handler: async (response) => {
          resolve({
            paymentID: response.razorpay_payment_id,
            orderID: response.razorpay_order_id,
            razorPaySig: response.razorpay_signature,
            cart: cart,
          });
          // try {
          await fetch(`${API_URL}razorpay/capture/${onlinePaymentId}`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "X-Temple-Code": templeID,
            },
            body: JSON.stringify({
              orderId: response.razorpay_order_id,
              razorPaymentId: response.razorpay_payment_id,
              razorSignature: response.razorpay_signature,
              // vendorType: vendorType,
            }),
          });
          // } catch (E) {
          //   console.log(E)
          // }
          // successData = await successData.json();
          // console.log(successData);
          // console.log(response);
        },
        prefill: {
          name: userData?.display_name,
          email: userData?.email,
          contact: userData.phone,
        },
      };
      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
      // paymentObject.onRejected((resp) => {
      //   console.log(`rejected`);
      //   console.error(`rejected`);
      //   console.log(resp);
      // });
      paymentObject.on("modal.ondismiss", function () {
        // console.log(`modal.ondismiss`);
        // console.log(response);
      });
      paymentObject.on("dismiss", function () {
        // console.log(`dismiss`);
        // console.log(response);
      });
      paymentObject.on("payment.failed", async (response) => {
        // console.log("dismissed");
        let failedData = await fetch(
          `${API_URL}razorpay/razorpay/failed/${onlinePaymentId}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
              "X-Temple-Code": templeID,
            },
            body: JSON.stringify({
              orderID: response.error.metadata.order_id,
              razorPaymentId: response.error.metadata.payment_id,
            }),
          }
        );
        await failedData.json();
        // console.log(failedData);
        // console.log(response);
      });
    } catch (e) {
      return reject(e.message);
    }
  });
}

export default DisplayRazorpay;
