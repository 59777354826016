import React, { createContext, useState } from "react";
import Sd_loading from "../Components/sd-common-components/sd-loading";
import Sd_DashboardService from "../services/sd-dashboard.service";
export const SD_Context = createContext();
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UserService from "../services/sd-user.service";
import { API_URL } from "../Utils/sd-axios/sd-api-url";
import { withRouter } from "react-router-dom";

toast.configure();

const SD_Context_Provider = ({ children, history }) => {
  const AddressRegex = /^[#.0-9a-zA-Z\s,-/]+$/;

  const [activeTopHeader, setActiveTopHeader] = useState("SrisailaTV");
  const [activeDownHeader, setActiveDownHeader] = useState("");
  const [currentDashboard, setCurrentDashboard] = useState("home");
  const [userData, setUserData] = useState({});
  const [registerDetails, setRegisterdetails] = useState({});
  const [parokshaSevaData, setParokshaSevaData] = useState([]);
  const [pratyakshaSevaData, setPratyakshaSevaData] = useState([]);
  const [donationData, setDonationData] = useState([]);
  const [currentBookingDonation, setcurrentBookingDonation] = useState(null);
  const [currentDashbaordLower, setCurrentDashboardLower] = useState("");
  const [fav, setFav] = useState([]);
  const [cbSrisailaPrabha, setCbSrisailaPrabha] = useState(null);
  const [currentBookingPratyaksha, setcurrentBookingPratyaksha] =
    useState(null);
  const templeID = process.env.REACT_APP_TEMPLE ?? "SDMSD";
  const [currentBookingDarshananam, setcurrentBookingDarshananam] =
    useState(null);
  const [currentBookingAccomadations, setcurrentBookingAccomdations] =
    useState(null);
  const [display, setDisplay] = useState(true);
  const [currentBooking, setCurrentBooking] = React.useState(null);
  const [currentBookingDetails, setCurrentBookingDetails] =
    React.useState(null);
  const [devoteeList, setDevoteeList] = useState(null);
  const [publicationData, setPublicationData] = useState([]);
  const [visitorCount, setVisitorCount] = useState({});
  const [language, setlanguage] = useState({ desc: "ENGLISH", code: "en-in" });
  const [active, setActive] = useState("devotee-dashboard");
  const [activePath, setActivePath] = useState("Devotee-dashboard");
  const [darshanamData, setDrashanamData] = useState([]);
  const api_url = API_URL;
  const [loading, setLoading] = useState(false);
  const supportedLanguages = ["en-in", "jp-in", "de-in", "es-in"];
  const [paymentData, setPaymentData] = useState(null);
  const [paymentPageFlag, setPaymentPageFlag] = useState(false);
  const [addonsApiRes, setAddonsApiRes] = useState([]);
  const [addonsList, setAddonsList] = useState([]);
  const [addonIds, setAddonIds] = useState([]);
  const [addonSlotIds, setAddonSlotIds] = useState([]);
  const [addonSlotsData, setAddonSlotsData] = useState([]);
  const [addonTypes, setAddonTypes] = useState([]);
  const [selectedCard, setSelectedCard] = useState();
  const [addonPersonStates, setAddonPersonStates] = useState([
    {
      id: 1,
      name: "",
      idProofNumber: "",
    },
  ]);
  const [noPersons, setNoperons] = useState(1);
  const commonMatchFormat = (item) =>
    item
      ?.replace?.(/\s/g, "")
      ?.replace?.(/[{()}]/g, "")
      ?.replaceAll("-", "")
      .toLowerCase();

  const logout = async () => {
    try {
      setLoading(true);
      await Sd_DashboardService.logout();
      localStorage.clear();
      setCurrentBooking(null);
      setCurrentBookingDetails(null);
      setCbSrisailaPrabha(null);
      setcurrentBookingAccomdations(null);
      setDevoteeList(null);
      setcurrentBookingPratyaksha(null);
      setcurrentBookingDonation(null);
    } catch (e) {
      setLoading(false);
      // console.log(object);
    }
    setLoading(false);
  };

  function numFormatter(num) {
    if (num > 999 && num < 100000) {
      return (num / 1000).toFixed(2) + "K"; // convert to K for number from > 1000 < 1 million
    }
    if (num > 100000 && num < 10000000) {
      return (num / 100000).toFixed(2) + "L"; // convert to K for number from > 1000 < 1 million
    } else if (num > 10000000) {
      return (num / 10000000).toFixed(2) + "Cr"; // convert to M for number from > 1 million
    } else if (num < 1000) {
      return num; // if value < 1000, nothing to do
    }
  }

  const getFavorities = async () => {
    if (localStorage.getItem("accessToken")) {
      // setLoading(true);
      try {
        let res = await Sd_DashboardService.get_favorities(
          process.env.REACT_APP_TEMPLE ?? "SDMSD"
        );
        setFav(res?.items ?? []);
        return;
      } catch (e) {
        // console.log(e);
        // return new Error();
      }
    } else {
      return;
    }
  };
  const [roomMapData, setRoomMapData] = useState([]);

  const addFavorite = async (data, type) => {
    if (localStorage.getItem("accessToken")) {
      try {
        let datas = {
          type: type,
          itemId: data.id,
        };
        await Sd_DashboardService.add_Favorite(
          datas,
          process.env.REACT_APP_TEMPLE ?? "SDMSD"
        );
        toast.success(`Added ${data.name} Favourites updated!!`);
        await getFavorities();
        // console.log(res);
        // return "ok";
      } catch (e) {
        // console.log(e);
        toast.error(e.message);
      }
    } else {
      return "redirect";
      // toast.error("Please login to add favorities");
    }
  };

  const getVisitors = async () => {
    try {
      console.log(templeID);
      let res = await UserService.getVisitors(templeID);
      setVisitorCount(res);
    } catch (e) {
      // toast.error(e?.message);
    }
    // console.log(res);
  };

  const removeFavorite = async (id) => {
    if (localStorage.getItem("accessToken")) {
      try {
        await Sd_DashboardService.remove_Favorite(
          id,
          process.env.REACT_APP_TEMPLE ?? "SDMSD"
        );
        toast.success(`Removed item from Favourites!`);

        await getFavorities();
        // console.log(res);
      } catch (e) {
        if (e.message === "Session Expired") {
          logout();
          history?.push(`/`);
        }
      }
      // console.log(e);      }}
    } else {
      toast.error("Please login to add favorities");
    }
  };

  React.useEffect(() => {
    getFavorities();
  }, [localStorage.getItem("accessToken")]);

  return (
    <SD_Context.Provider
      value={{
        commonMatchFormat,
        addonPersonStates,
        setAddonPersonStates,
        addonsApiRes,
        setAddonsApiRes,
        addonsList,
        setAddonsList,
        addonTypes,
        setAddonTypes,
        addonIds,
        setAddonIds,
        addonSlotIds,
        setAddonSlotIds,
        addonSlotsData,
        setAddonSlotsData,
        templeID,
        cbSrisailaPrabha,
        numFormatter,
        setCbSrisailaPrabha,
        getVisitors,
        publicationData,
        setPublicationData,
        visitorCount,
        setVisitorCount,
        darshanamData,
        setDrashanamData,
        donationData,
        setDonationData,
        addFavorite,
        removeFavorite,
        getFavorities,
        loading,
        setLoading,
        devoteeList,
        setDevoteeList,
        currentBookingPratyaksha,
        setcurrentBookingPratyaksha,
        currentBookingAccomadations,
        setcurrentBookingAccomdations,
        currentBookingDarshananam,
        setcurrentBookingDarshananam,
        display,
        setDisplay,
        currentBookingDonation,
        setcurrentBookingDonation,
        activePath,
        setActivePath,
        active,
        setActive,
        supportedLanguages,
        currentBooking,
        setCurrentBooking,
        currentBookingDetails,
        setCurrentBookingDetails,
        currentDashbaordLower,
        setCurrentDashboardLower,
        parokshaSevaData,
        setParokshaSevaData,
        language,
        setlanguage,
        registerDetails,
        setRegisterdetails,
        activeTopHeader,
        setActiveTopHeader,
        activeDownHeader,
        setActiveDownHeader,
        currentDashboard,
        setCurrentDashboard,
        userData,
        setUserData,
        api_url,
        pratyakshaSevaData,
        setPratyakshaSevaData,
        fav,
        setFav,
        logout,
        roomMapData,
        setRoomMapData,
        setPaymentPageFlag,
        paymentPageFlag,
        AddressRegex,
        paymentData,
        setPaymentData,
        selectedCard,
        setSelectedCard,
        noPersons,
        setNoperons,
      }}
    >
      {loading ? <Sd_loading /> : children}
    </SD_Context.Provider>
  );
};

export default withRouter(SD_Context_Provider);
