const checkDuplicateStrings = (array) => {
  const encountered = {};
  return array.some((currentString) => {
    if (encountered[currentString]) {
      return true;
    }
    encountered[currentString] = true;
    return false;
  });
};
export { checkDuplicateStrings };
