import React from "react";
import { SD_divider } from "../../Assets/imageList";
import SD_Paroksha_Seva_One from "../../Components/body/paroksha-seva/sd-paroksha-seva-component-one";
// import Sd_breadcrumb from "../../Components/sd-common-components/sd-breadcrumb";

const Sd_private_policy = () => {
  return (
    <div>
      <SD_Paroksha_Seva_One from="Privacy Policy" />

      {/* <Sd_breadcrumb from="Privacy Policy"></Sd_breadcrumb> */}
      <div className="sd-t-and-c">
        <div className="sd-t-and-c-inner">
          <div className="top white-bc">
            <h1>Privacy Policy</h1>
            <img
              src={SD_divider}
              alt={SD_divider}
              style={{ height: "20px" }}
            ></img>
          </div>
          <>
            <br />
            <span style={{ paddingLeft: "0px" }}>
              Last Updated: December 01, 2023
            </span>
            <br />
            <br />
            Welcome to www.srikalahastheeswaraswamy.org, owned and maintained by
            Sri Kalahastheeswara Swamy vari Devasthanam (“us”, “we”, or “our”).
            Your access to and use of this site is based on your acceptance of
            and compliance with the Terms outlined below as well as any
            additional terms outlined within the specific service you access.
            These Terms apply to all visitors, users, and others who access or
            use these Sites. If you do not agree to be bound by all of the
            Terms, do not access or use this site. Because the Terms and
            Conditions contain legal obligations, please read them carefully.
            <br />
            <br />
            <b>ACCEPTANCE</b>
            <br /> By using this website, you are agreeing, without limitation
            or qualification, to be bound by, and to comply with, these Terms
            and Conditions and any other posted guidelines or rules applicable
            to any individual pages of this website, product, or service.
            <br />
            <br />
            <b>CONDITIONS & RESTRICTIONS OF USE</b>
            <br />
            Use of this website, its products, and services is subject to
            compliance with these Terms and Conditions. You acknowledge and
            agree that Sri Kalahastheeswara Swamy vari Devasthanam may terminate
            your access to this Website or any of the products and services
            listed on the website should you fail to comply with the Terms and
            Conditions or any other guidelines and rules published by Sri
            Kalahastheeswara Swamy vari Devasthanam. Any such termination shall
            be at Sri Kalahastheeswara Swamy vari Devasthanam’s sole discretion
            and may occur without prior notice, or any notice. Sri
            Kalahastheeswara Swamy vari Devasthanam further reserves the right
            to terminate any user’s access to this website or to any of the
            products and services listed in the website if any conduct that Sri
            Kalahastheeswara Swamy vari Devasthanam, in its sole discretion,
            believes is or may be directly or indirectly harmful to other users
            toSri Kalahastheeswara Swamy vari Devasthanam, affiliates or other
            third parties, or for any conduct that violates any local, state,
            national, foreign laws or regulations. Sri Kalahastheeswara Swamy
            vari Devasthanam further reserves the right to terminate any user’s
            access to this Website or any of the website's products and services
            for any reason or no reason at all, in Sri Kalahastheeswara Swamy
            vari Devasthanam’ sole discretion, without prior notice, or any
            notice. Sri Kalahastheeswara Swamy vari Devasthanam does not
            discriminate based on age, gender, race, religion, ethnicity,
            nationality, or any other protected status.
            <br />
            <br />
            <b>REGISTRATION AND PRIVACY</b>
            <br />
            Certain services/products of this website will require the user to
            register and provide certain data. In consideration of the use of
            such services/products, in registering and providing such data, you
            represent and warrant that: (a) the information about yourself is
            true, accurate, current, and complete (apart from optional items) as
            required by various Sri Kalahastheeswara Swamy vari Devasthanam
            Website registration forms and (b) you will maintain and promptly
            update the Registration Data to keep it true, accurate, current and
            complete. If you provide any information that is untrue, inaccurate,
            not current or incomplete, or Sri Kalahastheeswara Swamy vari
            Devasthanam has reasonable grounds to suspect that such information
            is untrue, inaccurate, not current or incomplete, Sri
            Kalahastheeswara Swamy vari Devasthanam has the right to suspend or
            terminate your account and refuse any and all current or future use
            of this website.
            <br />
            <br />
            <b>RESPONSIBILITY FOR MINORS</b>
            <br />
            In cases where you have authorized a minor to use this website, you
            recognise that you are fully responsible for: (i) the online conduct
            of such minor; (ii) controlling the minor’s access to and use of
            this website and (iii) the consequences of any misuse by the minor.
            <br /> <br />
            <b>MEMBER AND USER CONDUCT</b>
            <br />
            Certain sections or products/services of this website may require
            you to register. Upon registering, you will receive a password and
            Website ID. You are responsible for maintaining the confidentiality
            of the password and ID and are fully responsible for all activities
            that occur under your password or ID. You agree to (a) immediately
            notify Sri Kalahastheeswara Swamy vari Devasthanam of any
            unauthorized use of your password or account or any other breach of
            security, and (b) ensure that you exit from your account at the end
            of each session. The Sri Kalahastheeswara Swamy vari Devasthanam
            Website cannot and will not be liable for any loss or damage arising
            from your failure to comply with this requirement. You acknowledge
            and agree that all information, code, data, text, software, music,
            sound, photographs, pictures, graphics, video, chat, messages,
            files, or other materials (“Content”), whether publicly posted or
            privately transmitted, are the sole responsibility of the person
            from which such Content originated. This means that you, and not the
            Sri Kalahastheeswara Swamy vari Devasthanam Website, are entirely
            responsible for all Content that you upload, post, email or
            otherwise transmit via the Services. The Sri Kalahastheeswara Swamy
            vari Devasthanam Website does not control the user or third party
            Content posted anywhere and, as such, does not guarantee the
            accuracy, integrity or quality of such user or third-party Content.
            You acknowledge and agree that by using this website, you may be
            exposed to Content that is offensive or objectionable. Under no
            circumstances will the Sri Kalahastheeswara Swamy vari Devasthanam
            Website be liable in any way for any user or third-party Content,
            including, but not limited to, for any errors or omissions in any
            such Content, or for any loss or damage of any kind incurred as a
            result of the use of any such Content posted, emailed or otherwise
            transmitted via any platform. Sri Kalahastheeswara Swamy vari
            Devasthanam reserves the right to screen any content on this
            website. Sri Kalahastheeswara Swamy vari Devasthanam does not
            guarantee that any screening will be done to your satisfaction or
            that any screening will be done at all. Sri Kalahastheeswara Swamy
            vari Devasthanam reserves the right to monitor some, all, or no
            areas of this website for adherence to these Terms and Conditions or
            any other rules or guidelines posted by Sri Kalahastheeswara Swamy
            vari Devasthanam.
            <br />
            <br />
            <b>PROHIBITED CONDUCT</b>
            <br />
            You agree that you will not use Sri Kalahastheeswara Swamy vari
            D﻿evasthanam Website Products and Services to:
            <br />
            <span>1.</span> Upload, post, email, otherwise transmit, or post
            links to any Content, or select any member or user name or email
            address, that is unlawful, harmful, threatening, abusive, harassing,
            tortious, defamatory, vulgar, obscene, pornographic, libelous,
            invasive of privacy or publicity rights, hateful, or racially,
            sexually, ethnically or otherwise objectionable.
            <br />
            <span>2.</span> Upload, post, email, otherwise transmit, or post
            links to any Content that promotes illegal activity, including
            without limitation the provision of instructions for illegal
            activity. • Upload, post, email, otherwise transmit, or post links
            to any Content that exploits the images of children under 18 years
            of age, or that discloses personally-identifying information
            belonging to children under 18 years of age.
            <br />
            <span>3.</span> Harm minors in any other way.
            <br />
            <span>4.</span> “Stalk” or otherwise harass another.
            <br />
            <span>5.</span> Collect or store personally identifying information
            about other users for commercial or unlawful purposes.
            <br />
            <span>6.</span> Impersonate any person or entity, including, but not
            limited to, a Sri Kalahastheeswara Swamy Vari Devasthanam official,
            employee, forum leader, guide or host, or falsely state or otherwise
            misrepresent your affiliation with a person or entity.
            <br />
            <span>7.</span> Upload, post, email, or otherwise transmit, or post
            links to any Content that facilitates hacking.
            <br />
            <span>8.</span> Upload, post, email, otherwise transmit, or post
            links to any Content that infringes any patent, trademark, service
            mark, copyright, or other proprietary rights (“Rights”) of any
            party, or contributing to inducing or facilitating such
            infringement.
            <br />
            <span>9.</span> Upload, post, email, otherwise transmit, or post
            links to any material that is false, or misleading. You acknowledge
            and agree that the Sri Kalahastheeswara Swamy Vari Devasthanam vari
            Devasthanam Website and its designees shall have the right (but not
            the obligation), at their sole discretion, to refuse to publish,
            remove, or block access to any Content that is available at any
            time, for any reason, or no reason at all, with or without notice.
            You acknowledge and agree that you must evaluate, and bear all risks
            associated with, the use of any Content, including any reliance on
            the accuracy, completeness, or usefulness of such Content.
            <br />
            <br />
            <b>DISCLAIMER OF WARRANTIES/REFUNDS </b>
            <br />
            The products and services on this website are provided “AS IS,” with
            NO WARRANTIES whatsoever. Donations or payments made once cannot be
            Canceled, Refunded, or Transferred.
            <br />
            <br />
            <b>LIMITATION OF LIABILITY</b>
            <br />
            In no event shall Sri Kalahastheeswara Swamy vari Devasthanam or its
            providers be liable for any damages (including, without limitation,
            damages for loss of data or profit, or due to business interruption)
            arising out of the use or inability to use the
            materials/products/services on our site, even if Sri
            Kalahastheeswara Swamy vari Devasthanam's authorized representative
            has been notified of the possibility of such damage. By
            participating in this Site, you undertake to indemnify the Sri
            Kalahastheeswara Swamy vari Devasthanam and its employees and
            subcontractors against any liability arising from any illegal and
            unlawful Content published on a Site and against all damages,
            losses, claims and costs (including without limitation all expenses
            incurred in conducting or defending any proceedings) arising from
            any such publication.
            <br />
            <br />
            <b>
              CHANGES IN TERMS AND CONDITIONS AND CHANGES IN PRODUCTS AND
              SERVICES
            </b>
            <br />
            The Sri Kalahastheeswara Swamy vari Devasthanam Website reserves the
            right to modify the Products and Services from time to time, for any
            reason, and without notice, including the right to terminate the
            Products and Services. The Sri Kalahastheeswara Swamy vari
            Devasthanam Website reserves the right to modify these Terms and
            Conditions from time to time, without notice. Please review these
            Terms and Conditions from time to time so you will be apprised of
            any changes.
            <br />
            <br />
            <b>VIOLATIONS OF TERMS AND CONDITIONS</b>
            <br />
            Should you violate these Terms and Conditions or any other rights of
            Sri Kalahastheeswara Swamy vari Devasthanam and the Sri
            Kalahastheeswara Swamy vari Devasthanam Website, we reserve the
            right to pursue any and all legal and equitable remedies against
            you, including, without limitation, terminating any and all user
            accounts on any and all of the Sri Kalahastheeswara Swamy vari
            Devasthanam Website. If you are aware of any violations of these
            Terms and Conditions, please report them to us. Thank you for taking
            the time to read these Terms and Conditions.
            <br />
            <br />
            <b>PRIVACY POLICY</b>
            <br />
            Your privacy is very important to us. For that reason, please read
            the following details carefully and contact us if you have any
            queries. By visiting, making a donation or transaction through, or
            registering with Sri Kalahastheeswara Swamy vari Devasthanam
            website, you are agreeing with this Privacy Policy. Your continued
            use of the Site constitutes your acknowledgment and agreement with
            the terms of this Privacy Policy.
            <br />
            Please be assured that www.srikalahastheeswaraswamy.org is committed
            to protecting the privacy of visitors to this website and the
            security of the data they provide. <br />
            <br />
            <b>OUR COMMITMENT TO PRIVACY</b>
            <br />
            www.srikalahastheeswaraswamy.org does not rent, sell, exchange or
            share our mailing lists nor any information submitted through this
            website. To better protect your privacy we provide this notice
            explaining our online information practices and the choices you can
            make about the way your information is collected and used.
            <br />
            <br />
            <b>OUR COMMITMENT TO DATA SECURITY</b>
            <br />
            To prevent unauthorized access, maintain data accuracy, and ensure
            the correct use of information, we have put in place appropriate
            physical, electronic and managerial procedures to safeguard and
            secure the information we collect online.
            <br />
            <br />
            <b>TYPES OF INFORMATION WE COLLECT</b>
            <br />
            www.srikalahastheeswaraswamy.org only gathers personal information,
            such as first and last names, home addresses, phone numbers and
            email addresses, etc when voluntarily submitted by you. If you make
            an online donation/purchase, personal information will be collected
            from you to process that donation/purchase. We do not store credit
            card information or other financial information.
            <br />
            <br />
            <b>HOW WE USE THE INFORMATION YOU PROVIDE</b>
            <br />
            With your permission, we will use personal information collected
            from you to send you information, such as updates, useful
            information regarding your contribution, and additional news about
            our services. If you do not elect to receive such materials, they
            will not be sent to you. For each visitor to this website, our
            servers automatically collect information about which pages are
            visited and the domain name of visitors. This information is used
            for internal review, to tailor information to individual visitors,
            for website traffic audits and other internal purposes.
            www.srikalahastheeswaraswamy.org reserves the right to use or
            disclose any information as needed to satisfy any law, regulation or
            legal request, to protect the integrity of the site, to fulfill your
            requests, or to cooperate in any law enforcement investigation or an
            investigation on a matter of public safety.
            <br />
            <br />
            <b>ACCESS </b>
            <br />
            You are in control of any personal information you provide to us
            online. If at any time you would like to correct the personal
            information we have about you or if you would like to change your
            preferences for contacts from us, you can let us know by contacting
            us at the postal address listed below or emailing us. You can also
            ask to review any personal information we have about you or ask us
            to delete that information or stop further use of it by writing to
            us at the same postal address or emailing us.
            <br />
            <br />
            <b>COOKIES </b>
            <br />
            “Cookies” are pieces of information that a website transfers to an
            individual’s hard drive for record-keeping purposes. Cookies allow
            the website to remember important information that will make your
            use of the site more convenient. Like most websites,
            www.srikalahastheeswaraswamy.org uses cookies for a variety of
            purposes to improve your online experience and so that we can better
            serve you when you return to our site. You can set your browser to
            notify you when you receive a cookie, giving you the chance to
            decide whether to accept it or not. If you disable all cookies, you
            may not be able to take advantage of all the features of this
            website.
            <br />
            <br />
            <b>CHANGES TO OUR PRIVACY POLICY</b>
            <br />
            From time to time, it may be necessary for
            www.srikalahastheeswaraswamy.org to change this privacy policy with
            or without notice, so we suggest that you check here periodically.
          </>
        </div>
      </div>
    </div>
  );
};
export default Sd_private_policy;
