// import Axios from "../Utils/sd-axios/Axios";
import axios from "axios";
const url =
  process.env.REACT_APP_API_URL ?? "https://api.aptemples.org/api/v1/";
const NewsletterSubService = {
  subscribe: async (email, templeID) => {
    try {
      const resp = await axios.post(
        url.replace("/v1", "") + `sub-newsletter/sub?email=${email}`,
        {},
        {
          headers: {
            "X-Temple-Code": templeID,
          },
        }
      );
      return resp.data;
    } catch (error) {
      if (error?.response?.status !== 500 && error?.response?.data?.message) {
        throw new Error(error?.response?.data?.message);
      } else {
        //console.error(error);
        throw new Error("something is wrong");
      }
    }
  },
};

export default NewsletterSubService;
