import moment from "moment";
export const DEFAULT_TIME_FORMAT = "HH:mm:ss Z";
export const bookingTimeIsWithinProductTime = (
  bookingDate,
  startTime,
  startTimeOffsetHours,
  endTimeOffsetHours
) => {
  const currentTimeMoment = moment().tz("Asia/Kolkata");
  const bookingDateMoment = moment.tz(bookingDate, "Asia/Kolkata").set({
    hour: currentTimeMoment.hour(),
    minute: currentTimeMoment.minute(),
    second: currentTimeMoment.second(),
  });

  const startDateTimeMoment = moment(startTime, DEFAULT_TIME_FORMAT).tz(
    "Asia/Kolkata"
  );

  const startTimeMoment = bookingDateMoment
    .clone()
    .set({
      hour: startDateTimeMoment.hour(),
      minute: startDateTimeMoment.minute(),
      second: startDateTimeMoment.second(),
    })
    .subtract(endTimeOffsetHours, "hours");

  const endTimeMoment = bookingDateMoment
    .clone()
    .set({
      hour: startDateTimeMoment.hour(),
      minute: startDateTimeMoment.minute(),
      second: startDateTimeMoment.second(),
    })
    .subtract(startTimeOffsetHours, "hours");

  if (endTimeMoment.isBefore(startTimeMoment)) {
    endTimeMoment.add(1, "days");
  }

  const resp = currentTimeMoment.isBetween(startTimeMoment, endTimeMoment);
  return resp;
};
