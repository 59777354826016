import React, { useContext, useEffect, useRef, useState } from "react";
import { withRouter } from "react-router";
import { toast } from "react-toastify";
import {
  sd_dashboard_edit_ico,
  sd_dashboard_side_arrow,
  sd_failure_icon,
  sd_success_icon,
} from "../../../../Assets/imageList";
import Sd_loading from "../../../../Components/sd-common-components/sd-loading";
import { SD_Context } from "../../../../context/sd-context";
import Sd_DashboardService from "../../../../services/sd-dashboard.service";
import ParokshaSevaService from "../../../../services/sd-parokshaseva.service";
import { BASE_BANK } from "../../../../Utils/sd-axios/sd-api-url";
import DisplayRazorpay from "../../../../Utils/sd-razorpay/sd-razorpay-display";
const Sd_dashboard_srisaila_prabha_confirm = ({ history }) => {
  //-------------------------------------------------------------states-----------------------------------------------------------//
  const { setActive, setActivePath, cbSrisailaPrabha, userData, logout } =
    useContext(SD_Context);
  const supportedLanguages = ["en-in", "jp-in", "de-in", "es-in"];
  const [selectedLanguage, setSelectedlanguage] = React.useState("en-in");
  const [loading, setloading] = useState(true);
  const [successOrfailureFlag, setSuccessOrfailureFlag] = useState(false);
  const [status, setStatus] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [dissmissed, setDissmissed] = useState("");
  const [halfState, setHalfState] = useState(null);
  const [charges, setCharges] = useState("");
  const refContainer = useRef();
  //---------------------------------------------------------------states-----------------------------------------------------------//

  //---------------------------------------------------------------useeffects-----------------------------------------------------------//
  useEffect(async () => {
    setActive("srisaila-prabha");
    setActivePath("Onlinebooking-Srisaila Prabha-Confirm");
    if (
      supportedLanguages.includes(
        history?.location?.pathname?.split?.("/")?.[1]
      )
    ) {
      setSelectedlanguage(history?.location?.pathname?.split?.("/")?.[1]);
    } else {
      setSelectedlanguage("en-in");
    }
    if (!cbSrisailaPrabha) {
      history?.push(
        `/${selectedLanguage}/devotee/signin?to=devotee-app/online-booking/srisaila-prabha`
      );
    }
    let charges = await Sd_DashboardService.get_taxes(
      cbSrisailaPrabha?.subType === "1 year" ? 116 : 1500
    );
    setCharges(charges);
    setloading(false);
  }, []);
  useEffect(() => {
    // console.log(dissmissed);
    if (dissmissed === "failed") {
      setErrorMsg("Cancelled by User");
      setSuccessOrfailureFlag(true);
      setStatus("failed");
      setloading(false);
      setDissmissed("");
      // console.log("dismiised in paroksha seva");
    }
  }, [dissmissed]);
  useEffect(() => {
    refContainer?.current?.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  }, [status]);
  //-------------------------------------------------------------useeffects-----------------------------------------------------------//

  //-------------------------------------------------------------functions-----------------------------------------------------------//
  const handleSubmit = async () => {
    let res;
    if (!halfState) {
      try {
        let data = {
          full_name: cbSrisailaPrabha?.name,
          address: cbSrisailaPrabha?.address1,

          type: cbSrisailaPrabha?.subType,
          contact: `+91${cbSrisailaPrabha?.mobile}`,
          pin_code: cbSrisailaPrabha?.zip,
          state: cbSrisailaPrabha?.state,
          country: cbSrisailaPrabha?.country,
          email: cbSrisailaPrabha?.email,
          city: cbSrisailaPrabha?.city,
          language: cbSrisailaPrabha?.lang,
        };
        if (cbSrisailaPrabha?.address2) {
          data.address2 = cbSrisailaPrabha?.address2;
        }
        setloading(true);
        let schema = [
          {
            srisailaPrabhaBooking: data,
            itemType: "srisailaPrabha",
            itemId: 1, // any positive greater than 0
          },
        ];
        res = await ParokshaSevaService.bookings(schema, BASE_BANK, false);
        setHalfState(res);
        // setResult(res);
      } catch (e) {
        // console.log(e);
        toast.error(e.message);
        if (e.message === "Session Expired") {
          logout();

          history?.push(
            `/${selectedLanguage}/devotee/signin?to=devotee-app/online-booking/srisaila-prabha`
          );
        }
        setloading(false);
        return;
      }
    }

    try {
      if (halfState !== null) {
        await DisplayRazorpay(
          halfState?.paymentResp?.razorResp?.id,
          halfState?.paymentResp?.razorResp?.currency,
          halfState?.paymentResp?.razorResp?.amount.toString(),
          halfState?.cart,
          BASE_BANK,
          setDissmissed,
          userData,
          dissmissed
        );
        // console.log(result);
        setHalfState(null);
        setSuccessOrfailureFlag(true);
        setStatus("success");
        setloading(false);
      } else {
        await DisplayRazorpay(
          res?.paymentResp?.razorResp?.id,
          res?.paymentResp?.razorResp?.currency,
          res?.paymentResp?.razorResp?.amount.toString(),
          res?.cart,
          BASE_BANK,
          setDissmissed,
          userData,
          dissmissed
        );
        // console.log(result);
        setHalfState(null);
        setSuccessOrfailureFlag(true);
        setStatus("success");
        setloading(false);
      }
    } catch (e) {
      // console.log(e);
      setErrorMsg(e.message);
      setSuccessOrfailureFlag(true);
      setStatus("failed");
      setloading(false);
    }
  };
  const getStatusFunction = () => {
    switch (status) {
      case "success":
        return (
          <div className="sd-success" ref={refContainer}>
            <div className="sd-success-top sd-green">
              <img src={sd_success_icon} alt="success" /> <p>Success</p>
            </div>
            <div className="sd-success-bottom">
              <h3>
                Subscription to Srisaila Prabha {cbSrisailaPrabha?.subType}.
                Thanks for subscribing! We have received your payment and you
                are now a subscribed member of the Srisaila Prabha{" "}
                <span className="mr-3">{cbSrisailaPrabha?.subType}</span> month
                plan.
              </h3>
              <div className="sd-success-bottom-down">
                <p
                  className="sd-border-right"
                  onClick={() => {
                    history?.push(
                      `/${selectedLanguage}/devotee-app/booking-history`
                    );
                  }}
                >
                  Booking History
                </p>{" "}
                <p
                  onClick={() => {
                    history?.push(`/${selectedLanguage}/home`);
                  }}
                >
                  Go to Home
                </p>
              </div>
            </div>
          </div>
        );
      case "failed":
        return (
          <div className="sd-success" ref={refContainer}>
            <div className="sd-success-top sd-redback">
              <img src={sd_failure_icon} alt="success" />{" "}
              <p>Sorry, your Subscription failed!. {errorMsg} </p>
            </div>
            <div className="sd-success-bottom">
              <h3>
                Subscription failed! An error has occurred. Check your booking
                history or SMS after 20 mins for confirmation. Please note that
                any funds debited will credit back to your account within 7
                working days.
              </h3>
              <div className="sd-success-bottom-down">
                <p
                  className="sd-border-right"
                  onClick={() => {
                    setStatus("");
                    setSuccessOrfailureFlag(false);
                  }}
                >
                  Try Again
                </p>
                <p
                  onClick={() => {
                    history?.push(`/${selectedLanguage}/support/contactUs`);
                  }}
                >
                  Contact Us
                </p>
              </div>
            </div>
          </div>
        );
    }
  };
  //-------------------------------------------------------------functions-----------------------------------------------------------//
  return (
    <div
      className="col-xs-12 col-sm-12 col-md-9 col-lg-9 sd-dashboard"
      style={{
        minHeight: document.getElementById("side-menu")?.offsetHeight,
      }}
    >
      {loading ? (
        <Sd_loading />
      ) : successOrfailureFlag ? (
        getStatusFunction()
      ) : (
        <div className="sd-confirm-details">
          <h2 className="sd-side-heading fw400">
            <span className="fw400">
              <span className="fw700">Confirm</span> Details
            </span>
            <a
              onClick={() => {
                history?.push(
                  `/${selectedLanguage}/devotee-app/online-booking/srisaila-prabha`
                );
              }}
              className="sd-confirm-edit-button"
            >
              <img src={sd_dashboard_edit_ico} /> Edit details
            </a>
          </h2>
          <div className="clearfix  sd-booking-details">
            <h4>Booking Details</h4>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th width="40%">Subscription for</th>
                  <th width="30">Language</th>
                  <th width="30%">Price</th>
                  {/* <th width="20%">Total Price</th> */}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      paddingLeft: "10px !important",
                    }}
                  >
                    {cbSrisailaPrabha?.subType === "1 year"
                      ? "Srisaila Prabha (Annual- 1 year)"
                      : "Srisaila Prabha (Saswatha - 15 years)"}
                    <br />{" "}
                  </td>
                  <td>
                    {" "}
                    {cbSrisailaPrabha?.lang && (
                      <span style={{ textTransform: "capitalize" }}>
                        {cbSrisailaPrabha?.lang}
                      </span>
                    )}
                  </td>
                  <td>
                    Rs. {charges?.totalAmount}.00 <br /> (Handling charges:{" "}
                    {~~charges?.additionalAmount}.00)
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="clearfix sd-devotee-details mt-30">
            <h4>Devotee Details</h4>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th width="5%">S.No.</th>
                  <th width="20%">Name</th>
                  <th width="25%">Mobile</th>
                  <th width="20%">Email</th>
                  <th width="20%"> Address</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1.</td>
                  <td style={{ textTransform: "capitalize" }}>
                    {cbSrisailaPrabha?.name}
                  </td>
                  <td>{cbSrisailaPrabha?.mobile}</td>
                  <td>{cbSrisailaPrabha?.email}</td>
                  <td>
                    {cbSrisailaPrabha?.address1}.{cbSrisailaPrabha?.address2}
                    <br />
                    {cbSrisailaPrabha?.city},{cbSrisailaPrabha?.state},
                    {cbSrisailaPrabha?.country}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="clearfix mt-50 sd-form">
            <a
              className="sd-btn-orange"
              onClick={() => {
                if (!loading) {
                  handleSubmit();
                }
              }}
            >
              Proceed to Payment{" "}
              <img
                className="sd-arrow-btn ml-5"
                src={sd_dashboard_side_arrow}
              />
            </a>
          </div>
        </div>
      )}{" "}
    </div>
  );
};
export default withRouter(Sd_dashboard_srisaila_prabha_confirm);
