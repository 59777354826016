import React, { useContext, useEffect, useRef, useState } from "react";
import { sd_rupee } from "../../Assets/imageList";
import PratyakshaSevaService from "../../services/sd-pratyakshaseva.service";
import { toast } from "react-toastify";
import { SD_Context } from "../../context/sd-context";
import moment from "moment";
import Sd_Darshanam_service from "../../services/sd-darshanam.service";
import { bookingTimeIsWithinProductTime } from "../../Utils/sd-slot-management/slot-management";

const Addons = ({ selectedTemple, startDate }) => {
  const selectedlanguage = "en-in";
  const ref = useRef(null);
  const {
    logout,
    addonsApiRes,
    addonTypes,
    addonsList,
    setAddonsList,
    addonIds,
    setAddonIds,
    addonSlotIds,
    setAddonSlotIds,
    addonSlotsData,
    setAddonSlotsData,
    addonPersonStates,
    setAddonPersonStates,
    selectedCard,
    setSelectedCard,
    noPersons,
    setNoperons,
  } = useContext(SD_Context);
  const [slotLoading, setSlotLoading] = useState(false);
  //   const [slot, setSlot] = useState("");
  const [err, setErr] = useState([]);
  const [slotComponentVisible, setSlotComponentVisible] = useState(false);

  const deleteAddon = (id, flag) => {
    if (flag) {
      setAddonSlotsData([]);
    }
    if (addonIds?.includes(id?.toLowerCase()?.replaceAll(" ", ""))) {
      let index = addonIds?.indexOf(id?.toLowerCase()?.replaceAll(" ", ""));
      addonIds?.splice(index, 1);
      addonSlotIds?.splice(index, 1);
      addonsList?.splice(index, 1);
    }
  };

  const slotManagement = (item) => {
    let a = true;
    if (a) {
      let data = [...err];

      addonPersonStates.map((item) => {
        for (var key of Object.keys(item)) {
          if (item[key] === "") {
            if (key === "idProofNumber") {
              data.push({
                type: `${item["id"]}-${key}`,
                msg: `Invalid id number`,
              });
            } else {
              data.push({
                type: `${item["id"]}-${key}`,
                msg: `Invalid ${key}`,
              });
            }
          } else {
            if (key === "name") {
              if (item["name"].length < 3) {
                data.push({
                  type: `${item["id"]}-name`,
                  msg: "Minimun 3 characters",
                });
              }
            }
            if (item["idProofNumber"] !== "") {
              let regex = /^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/;
              if (!regex.test(item["idProofNumber"].toUpperCase())) {
                data.push({
                  type: `${item["id"]}-idProofNumber`,
                  msg: "Invalid AADHAAR number",
                });
              }
            }
          }
        }
      });
      setErr(data);
      if (data.length !== 0) {
        ref.current?.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });

        return;
      }
    }

    deleteAddon(item?.productSlot?.product?.name, false);
    setAddonSlotIds([...addonSlotIds, item?.id]);
    setAddonsList([
      ...addonsList,
      { slot: item, devoteeList: addonPersonStates, noofPersons: noPersons },
    ]);
    setAddonIds([
      ...addonIds,
      item?.productSlot?.product?.name?.toLowerCase()?.replaceAll(" ", ""),
    ]);
  };

  const getSlotsDetails = () => {
    if (addonSlotsData?.length === 0 || !addonSlotsData) {
      return (
        <div
          className="clearfix sd-darshanam-content"
          style={{ position: "relative", color: "red" }}
        >
          No Slots available on {moment(startDate).format("DD-MM-YYYY")}
        </div>
      );
    }
    return addonSlotsData
      ?.filter((item) => moment(startDate).format("YYYY-MM-DD") === item?.date)
      ?.map((item, i) => {
        if (item?.availableQuantity < 1) {
          return;
        }
        return (
          <div
            key={i + "slots-prs"}
            onClick={() => {
              if (item?.availableQuantity < 1) {
                return;
              }
              slotManagement(item);
              if (err?.find((e) => e.type === `slot`)) {
                let data = err.filter((e) => e.type !== "slot");
                setErr(data);
              }
            }}
          >
            <div
              className="sd-darshanam-box text-center"
              style={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                border:
                  err.find((e) => e.type === "slot") ||
                  item?.availableQuantity < 1
                    ? "1px solid red"
                    : "",
              }}
              onClick={() => {
                if (addonPersonStates?.length > item?.availableQuantity) {
                  return;
                }
                slotManagement(item);
                if (err?.find((e) => e.type === `slot`)) {
                  let data = err.filter((e) => e.type !== "slot");
                  setErr(data);
                }
              }}
            >
              <p
                className="sd-darshanam-time"
                style={{ marginBottom: "0px", maxWidth: "85px" }}
              >
                <span style={{ marginRight: "3px", fontWeight: "600" }}>
                  {item.availableQuantity}
                </span>
                <span style={{ fontWeight: "400" }}> Available </span>
              </p>
              <div className="sd-side-heading-2 sd-acc">
                <span
                  style={{
                    fontWeight: "600",
                    whiteSpace: "nowrap",
                  }}
                >
                  Slot - (
                  <>
                    {" "}
                    {moment(item?.productSlot?.startTime, "HH:mm:ss").format(
                      "hh:mm A"
                    )}{" "}
                    )
                  </>
                </span>
              </div>
              <input
                className="sd-darshanam-radio"
                type="radio"
                id={"slot" + 11}
                name=""
                // value={slot}
                onClick={() => {
                  if (addonPersonStates?.length > item?.availableQuantity) {
                    return;
                  }
                  slotManagement(item);
                  if (err?.find((e) => e.type === `slot`)) {
                    let data = err.filter((e) => e.type !== "slot");
                    setErr(data);
                  }
                }}
                // checked={slot.id === item?.id}
                checked={
                  addonIds?.includes(
                    item?.productSlot?.product?.name
                      ?.toLowerCase()
                      ?.replaceAll(" ", "")
                  )
                    ? addonSlotIds?.includes(item?.id)
                      ? addonsList[addonSlotIds?.indexOf(item?.id)]
                          .noofPersons === noPersons
                        ? true
                        : false
                      : false
                    : false
                }
              />
            </div>
          </div>
        );
      });
  };

  const handleChange = (index, event, name) => {
    if (name === "idProofNumber") {
      let data = [...addonPersonStates];
      data[index][`idProofNumber`] = event.target.value
        ?.replaceAll?.(" ", "")
        ?.toUpperCase();
      setAddonPersonStates(data);
    } else {
      let data = [...addonPersonStates];
      data[index][`${name}`] = event.target.value;
      setAddonPersonStates(data);
    }
  };

  const listFunction = () => {
    if (noPersons === addonPersonStates?.length) {
      return;
    }
    if (noPersons < addonPersonStates?.length) {
      let arr = [...addonPersonStates];

      let data = err.filter((e) => {
        if (
          e.type !== `${addonPersonStates?.length}-name` &&
          e.type !== `${addonPersonStates?.length}-idProofNumber`
        ) {
          return e;
        }
      });
      setErr(data);
      arr.pop();

      setAddonPersonStates([...arr]);
      return;
    }
    if (noPersons > addonPersonStates?.length) {
      let arr = [...addonPersonStates];
      arr.push({
        id: addonPersonStates?.length + 1,
        name: "",
        idProofNumber: "",
      });
      setAddonPersonStates([...arr]);
      return;
    }
  };

  useEffect(() => {
    listFunction();
  }, [noPersons]);

  useEffect(() => {
    if (addonSlotsData?.length > 0) {
      setSlotComponentVisible(true);
    } else {
      setSlotComponentVisible(false);
    }
  }, []);

  const personStateManage = (data) => {
    if (
      addonIds?.includes(data?.child?.name?.toLowerCase().replaceAll(" ", ""))
    ) {
      setNoperons(
        addonsList[
          addonIds?.indexOf(
            data?.child?.name?.toLowerCase().replaceAll(" ", "")
          )
        ]?.noofPersons
      );
      setAddonPersonStates(
        addonsList[
          addonIds?.indexOf(
            data?.child?.name?.toLowerCase().replaceAll(" ", "")
          )
        ]?.devoteeList
      );
    } else {
      setNoperons(1);
      setAddonPersonStates([
        {
          id: 1,
          name: "",
          idProofNumber: "",
        },
      ]);
    }
  };

  const getQuota = async (data, i) => {
    setSelectedCard(i);
    personStateManage(data);
    if (slotLoading) {
      return;
    }
    setSlotComponentVisible(true);
    setSlotLoading(true);
    try {
      let resdata = [];
      if (data?.childType === "SEVA" || data?.childType === "DARSHANAM") {
        let res = await (data?.childType === "SEVA"
          ? PratyakshaSevaService
          : Sd_Darshanam_service
        ).getDailyQuota(
          moment(startDate).format("YYYY-MM-DD"),
          moment(startDate).format("YYYY-MM-DD"),
          Number(data?.child?.id),
          data?.child?.onlineEnabled,
          selectedTemple
        );
        res
          ?.filter((item) =>
            bookingTimeIsWithinProductTime(
              item?.date,
              item?.productSlot?.startTime,
              data?.child?.minOnlineHours || 1,
              data?.child?.maxOnlineHours
            )
          )
          ?.map((item) => {
            resdata = [
              ...resdata,
              {
                id: item?.id,
                type: data?.childType === "SEVA" ? "seva" : "darshanam",
                date: item?.date,
                availableQuantity: item?.availableQuantity,
                productSlot: {
                  id: item?.productSlot?.id,
                  startTime: item?.productSlot?.startTime,
                  endTime: item?.productSlot?.endTime,
                  product: {
                    name: item?.productSlot?.product?.name,
                    price: item?.productSlot?.product?.price,
                  },
                },
              },
            ];
          });
        setAddonSlotsData(resdata);
      }
      setSlotLoading(false);
    } catch (e) {
      ref.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });

      toast.error(e.message);
      if (e.message === "Session Expired") {
        logout();
        history?.push(
          `/${selectedlanguage}/devotee/signin?to=devotee-app/online-booking/pratyaksha-seva`
        );
      }
      setSlotLoading(false);

      return;
    }
  };
  return (
    <>
      <div className="form-group col-xs-12 col-sm-12 col-md-12 col-lg-12  xssm-pr-0 pl-0">
        <label style={{ display: "flex", columnGap: "5px" }}>
          Do you want to book any other{" "}
          {addonTypes?.map((item, i) => {
            return (
              <label
                style={{
                  textTransform: "lowercase",
                  color: "#f58220",
                  fontWeight: "bold",
                }}
                key={i}
              >
                {item}
                {addonTypes?.length - 1 === i ? <>.</> : <>,</>}
              </label>
            );
          })}
        </label>
      </div>
      <div className="sd-seva-booking">
        {addonsApiRes?.map((item, i) => {
          return (
            <div
              style={{
                cursor: "pointer",
                boxShadow: selectedCard === i ? "0px 0px 10px 0px grey" : "",
              }}
              className="seva-div-booking"
              key={i}
              onClick={(e) => {
                e.preventDefault();
                localStorage.setItem("selected-temple", selectedTemple);
                getQuota(item, i);
              }}
            >
              <img
                className="head-image-booking"
                src={item?.child?.imageUrl}
                alt={"image"}
              />
              <h4>{item?.child?.name}</h4>
              <h5>{item?.child?.description}</h5>
              <div>
                <p>
                  <img src={sd_rupee} alt="Rs." /> {item?.child?.price}
                </p>
                <div style={{ columnGap: "5px" }}>
                  <button
                    style={{
                      zIndex: "99",
                      backgroundColor: `${
                        addonIds?.includes(
                          item?.child?.name?.toLowerCase().replaceAll(" ", "")
                        )
                          ? "green"
                          : "#f58220"
                      }`,
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      localStorage.setItem("selected-temple", selectedTemple);
                      getQuota(item, i);
                    }}
                  >
                    {addonIds?.includes(
                      item?.child?.name?.toLowerCase().replaceAll(" ", "")
                    )
                      ? "Added"
                      : "Add"}
                  </button>
                  {addonIds?.includes(
                    item?.child?.name?.toLowerCase().replaceAll(" ", "")
                  ) && (
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        deleteAddon(item?.child?.name, true);
                      }}
                    >
                      Remove
                    </button>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      {slotComponentVisible && (
        <>
          <hr className="clearfix"></hr>
          <div className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6  xssm-pl-0 xssm-pr-0 pl-0 ">
            <label>
              Number of Person(s)<span className="sd-imp">*</span>
            </label>
            <select
              className={
                noPersons !== "" ? "form-control sd-voilet" : "form-control"
              }
              value={noPersons}
              onChange={(e) => {
                setNoperons(e.target.value);
              }}
            >
              <option key={1} value={1}>
                {1}
              </option>
              <option key={2} value={2}>
                {2}
              </option>
            </select>
          </div>
          <hr className="clearfix"></hr>
          {addonPersonStates?.map((item, i) => {
            return (
              <>
                {" "}
                <div className="clearfix" key={i + "personstates-prs"}>
                  <div
                    className="form-group col-xs-12 col-sm-12 col-md-1 col-lg-1  xssm-pr-0 pl-0 ml-0 mr-0"
                    style={{
                      width: "2vw",
                    }}
                  >
                    <label>{i + 1}.</label>
                  </div>
                  <div className="form-group col-xs-12 col-sm-12 col-md-11 col-lg-11  xssm-pl-0 pr-0">
                    <div className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6 xssm-pr-0 pl-0">
                      <label>
                        Name<span className="sd-imp">*</span>
                      </label>
                      <input
                        maxLength="20"
                        autoComplete="new-password"
                        // onFocus={() => {
                        //   if (autoFill === `${i}autofill`) {
                        //     setAutoFill("none");
                        //   } else {
                        //     setAutoFill(`${i}autofill`);
                        //   }
                        // }}
                        // onBlur={() => {
                        //   if (autoFill === `${i}autofill`) {
                        //     setTimeout(() => {
                        //       setAutoFill("none");
                        //     }, 250);
                        //   }
                        // }}
                        placeholder="Please enter your name"
                        type="text"
                        className={
                          err?.find((e) => e.type === `${i + 1}-name`)
                            ? "form-control sd-red"
                            : item?.name !== ""
                            ? "form-control sd-voilet"
                            : "form-control"
                        }
                        value={item?.name}
                        onChange={(e) => {
                          if (
                            /^[a-zA-Z ]*$/.test(String(e.target.value)) ||
                            e.target.value === ""
                          ) {
                            if (err.find((e) => e.type === `${i + 1}-name`)) {
                              let data = err.filter(
                                (e) => e.type !== `${i + 1}-name`
                              );
                              setErr(data);
                            }
                            handleChange(i, e, "name");
                          }
                        }}
                      />

                      <p className="sd-EM mr-15 ">
                        {err.find((e) => e.type === `${i + 1}-name`)?.msg}
                      </p>
                      {/* {autoFill === `${i}autofill` &&
                              autoFillFunction(item?.name, i)} */}
                    </div>

                    <div className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6 xssm-pl-0 xssm-pr-0 pl-0">
                      <label>
                        Aadhaar Number<span className="sd-imp">*</span>
                      </label>

                      <input
                        placeholder="Please enter your id number"
                        type="text"
                        className={
                          err?.find((e) => e.type === `${i + 1}-idProofNumber`)
                            ? "form-control sd-red"
                            : item?.idProofNumber !== ""
                            ? "form-control sd-voilet"
                            : "form-control"
                        }
                        maxLength={12}
                        value={item?.idProofNumber}
                        style={{ textTransform: "none" }}
                        onChange={(e) => {
                          if (
                            /^[#.0-9a-zA-Z\s]+$/.test(e.target.value) ||
                            e.target.value === ""
                          ) {
                            if (
                              err.find(
                                (e) => e.type === `${i + 1}-idProofNumber`
                              )
                            ) {
                              let data = err.filter(
                                (e) => e.type !== `${i + 1}-idProofNumber`
                              );
                              setErr(data);
                            }
                            handleChange(i, e, "idProofNumber");
                          }
                        }}
                      />
                      <p className="sd-EM mr-15 ">
                        {
                          err.find((e) => e.type === `${i + 1}-idProofNumber`)
                            ?.msg
                        }
                      </p>
                    </div>
                  </div>
                </div>
                <hr className="clearfix"></hr>
              </>
            );
          })}
          <>
            {slotLoading ? (
              <>
                <div
                  className="clearfix sd-darshanam-content"
                  style={{ position: "relative" }}
                >
                  Fetching slots...Please wait{" "}
                </div>
              </>
            ) : (
              <>
                <div
                  className="clearfix sd-darshanam-content"
                  style={{ position: "relative" }}
                >
                  <br />
                  {getSlotsDetails()}
                  <p
                    style={{
                      position: "absolute",
                      top: "-12px",
                      left: "0px",
                      textAlign: "right",
                      color: "red",
                    }}
                  >
                    {err?.find((e) => e.type === `slot`)?.msg}
                  </p>
                </div>
              </>
            )}
            <hr className="clearfix"></hr>
          </>
        </>
      )}
    </>
  );
};

export default Addons;
