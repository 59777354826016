import Axios from "../Utils/sd-axios/Axios";

const PratyakshaSevaService = {
  getAll: async () => {
    try {
      const resp = await Axios.get("/seva/all?sevaType=pratyakshaSeva");
      return resp.data;
    } catch (error) {
      if (error?.response?.status === 401) {
        throw new Error("Session Expired");
      } else if (
        error?.response?.status !== 500 &&
        error?.response?.data?.message
      ) {
        throw new Error(error?.response?.data?.message);
      } else {
        //console.error(error);
        throw new Error("something is wrong");
      }
    }
  },
  getAllOnline: async (id) => {
    try {
      const resp = await Axios.get("/seva/all/online", {
        headers: {
          "X-Temple-Code": id,
        },
      });
      return resp.data;
    } catch (error) {
      if (error?.response?.statusCode === 401) {
        throw new Error("Session Expired");
      } else if (
        error?.response?.statusCode !== 500 &&
        error?.response?.message
      ) {
        throw new Error(error?.response?.message);
      } else {
        //console.error(error);
        // throw new Error("something is wrong");
      }
    }
  },
  getAllvalidOnline: async (id) => {
    try {
      const resp = await Axios.get("/seva-online-booking/valid-products", {
        headers: {
          "X-Temple-Code": id,
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });
      return resp.data;
    } catch (error) {
      if (error?.response?.statusCode === 401) {
        throw new Error("Session Expired");
      } else if (
        error?.response?.statusCode !== 500 &&
        error?.response?.message
      ) {
        throw new Error(error?.response?.message);
      } else {
        //console.error(error);
        // throw new Error("something is wrong");
      }
    }
  },

  getDailyQuota: async (
    fromDate,
    toDate,
    // productSlotId,
    productId,
    onlyOnlineEnabled,
    id
  ) => {
    try {
      let query = `/seva-slot-daily-quota?fromDate=${fromDate}&toDate=${toDate}&productId=${productId}&onlyOnlineEnabled=${onlyOnlineEnabled}`;
      // if (productSlotId && productSlotId !== "") {
      //   query += `&productSlotId=${productSlotId}`;
      // }
      const resp = await Axios.get(query, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Code": id,
        },
      });
      return resp.data;
    } catch (error) {
      if (error?.response?.statusCode === 401) {
        throw new Error("Session Expired");
      } else if (
        error?.response?.statusCode !== 500 &&
        error?.response?.message
      ) {
        throw new Error(error?.response?.message);
      } else {
        //console.error(error);
        // throw new Error("something is wrong");
      }
    }
  },
};

export default PratyakshaSevaService;
