import moment from "moment";
import React, { useState } from "react";
import { withRouter } from "react-router";
import {
  calender,
  hand_icon,
  SD_divider,
  sun_icon,
} from "../../../Assets/imageList";
import "../../../Utils/sd-styles/sd-main-sup.scss";
const supportedLanguages = ["en-in", "jp-in", "de-in", "es-in"];

const TemplateFour = ({ history }) => {
  const [selectedlanguage, setSelectedlanguage] = React.useState("en-in");
  const [active, setActive] = useState("news");
  const timings = [
    { time: "04:00 AM - 05:00 AM", name: "Suprabhata Seva" },
    { time: "05:00 AM - 05:30 AM", name: "Nijaroopa Darshanam" },
    { time: "05:30 AM - 06:00 AM", name: "Panchamruthabhishekam" },
    { time: "06:00 AM - 06:15 AM", name: "Maha Harathi" },
    { time: "06:15 AM - 07:00 AM", name: "Sarva Darshanam" },
  ];

  React.useEffect(() => {
    if (
      supportedLanguages.includes(
        history?.location?.pathname?.split?.("/")?.[1]
      )
    ) {
      setSelectedlanguage(history?.location?.pathname?.split?.("/")?.[1]);
    } else {
      setSelectedlanguage("en-in");
    }
    console.log(selectedlanguage);
  }, []);
  return (
    <>
      <div className="top happenings-bg">
        <h1>Happenings in Temple</h1>
        <img src={SD_divider} alt="-" style={{ height: "20px" }}></img>
      </div>
      <div className="sd-template-four">
        <div className="sd-t4-one">
          <div className="sd-t4-one-start">
            <span className="span">Panchangam</span>
            <img src={sun_icon} alt="alt" />
          </div>
          <div className="sd-t4-calender">
            <span style={{ fontWeight: "600" }}>
              {moment().format("dddd, D MMM YYYY ")}
            </span>{" "}
            <img src={calender} />
          </div>
          <div
            style={{ textAlign: "center", margin: "0px" }}
            className="element-panchangam-wrapper"
          >
            <div className="element-panchangam">
              <div className="w-100">
                <p>Samvatsaram</p>
                <h5>Sri Shubhakrut Nama Samvathsaram</h5>
              </div>
            </div>
            <div className="element-panchangam">
              <div>
                <p>Bhadrapada</p>
                <h5>Sravana</h5>
              </div>
              <div>
                <p>Paksham</p>
                <h5>Krishna</h5>
              </div>
              <div>
                <p>Sunrise</p>
                <h5>05:48 a.m</h5>
              </div>
            </div>
            <div className="element-panchangam">
              <div>
                <p>Tithi</p>
                <h5>
                  Dasami upto <br /> 08:21 p.m <br /> afterwards Ekadasi
                </h5>
              </div>
              <div>
                <p>Nakshatram</p>
                <h5>
                  Punarvasu upto
                  <br /> 09:20 p.m <br />
                  afterwards Pushyami{" "}
                </h5>
              </div>
              <div>
                <p>Sunset</p>
                <h5>06:15 p.m</h5>
              </div>
            </div>
            <div className="element-panchangam">
              <div>
                <p>Yamagandam </p>
                <h5>
                  9:00 a.m <br /> to 10:30 a.m
                </h5>
              </div>
              <div>
                <p>Rahukalam </p>
                <h5>
                  03:00 p.m <br /> to 04:30 p.m
                </h5>
              </div>
              <div>
                <p>Amruta Gadi</p>
                <h5>
                  06:40 p.m <br />
                  to 08:26 p.m
                </h5>
              </div>
            </div>
            <div className="element-panchangam">
              <div>
                <p>Varjyam</p>
                <h5>08:01 a.m to 09:47 a.m</h5>
              </div>
              <div>
                <p>Durmuhurtham</p>
                <h5>08:18 a.m to 09:06 a.m</h5>
                <h5>10:42 p.m to 11:30 p.m</h5>
              </div>
            </div>
          </div>
        </div>
        <div className="sd-t4-one">
          <div className="sd-t4-one-middle">
            <span className="span" style={{ color: "#d09a49" }}>
              Day Schedule
            </span>{" "}
            <img src={hand_icon} alt="alt" style={{ filter: "invert(0)" }} />
          </div>
          <div className="sd-t4-calender">
            <span style={{ fontWeight: "600" }}>
              {moment().format("dddd, D MMM YYYY ")}
            </span>{" "}
            <img src={calender} />
          </div>

          <div className="sd-t4-one-end-m">
            {timings.map((item, i) => {
              return (
                <div key={"sasdasdsd " + i} className="element-date-m">
                  <div>{item.time}</div>
                  <p style={{ textTransform: "uppercase" }}>{item.name}</p>
                </div>
              );
            })}
          </div>
        </div>
        <div className="sd-t4-one">
          {" "}
          <div className="sd-t4-one-end">
            <span
              className={active === "news" ? "span-active" : ""}
              onClick={() => {
                setActive("news");
              }}
            >
              News
            </span>{" "}
            <span
              className={active === "events" ? "span-active" : ""}
              onClick={() => {
                setActive("events");
              }}
            >
              Events
            </span>{" "}
            <span
              className={active === "tenders" ? "span-active" : ""}
              onClick={() => {
                setActive("tenders");
              }}
            >
              Tenders
            </span>
          </div>
          <div className="sd-t4-one-end-d">
            {active === "news" ? (
              [
                {
                  data: "Dussera Celebrations will be started with great grandeur from 25-09-2022",
                  month: "SEP",
                  date: "09",
                },
                {
                  data: "Sri Kanaka Durgamma Temple gearing for 10 day Dussera festivities this year",
                  month: "SEP",
                  date: "09",
                },
                {
                  data: "Temple plans to introduce ₹500 ticket for VIPs",
                  month: "SEP",
                  date: "12",
                },
                {
                  data: "No relinquishment of Bhavani Deeksha atop Indrakeeladri during the festival",
                  month: "SEP",
                  date: "13",
                },
                {
                  data: "The authorities of Sri Kalahastheeswara Swamy Vari Devasthanam (SDMSD) said elaborate arrangements were being made for the annual Dasara festivities, which are going to be celebrated from September 26 to October 5.",
                  month: "SEP",
                  date: "14",
                },
                {
                  data: "Chief minister YS Jagan Mohan Reddy will visit the temple on October 2 on the occasion of Moolanakshatram and offer traditional clothes to the deity on behalf of the state government.",
                  month: "SEP",
                  date: "19",
                },
              ].map((item, i) => {
                return (
                  <div key={`sasdasd +${i}`} className="element-date">
                    <div
                      style={{
                        color: "#181818",
                        fontWeight: "600",
                        backgroundColor: i % 2 === 0 ? "#d09a49" : "#EDEDED",
                      }}
                    >
                      {item?.month}
                      <br />
                      <span style={{ color: "#181818" }}>{item?.date}</span>
                    </div>
                    <p style={{ color: "#171717" }}>{item?.data}</p>
                  </div>
                );
              })
            ) : active === "events" ? (
              [
                {
                  data: "Swarna Kavachalankritha Sri Kanaka Durga Devi alankaram will be on 26-09-2022",
                  month: "SEP",
                  date: "26",
                },
                {
                  data: "Sri Bala Tripura Sundari Devi alankaram will be on 27-09-2022",
                  month: "SEP",
                  date: "27",
                },
                {
                  data: "Sri Gayatri Devi alankaram will be on 28-09-2022",
                  month: "SEP",
                  date: "28",
                },
                {
                  data: "Sri Annapurna Devi alankaram will be on 29-09-2022",
                  month: "SEP",
                  date: "29",
                },
                {
                  data: "Sri Lalitha Tripura Sundari Devi alankaram will be on 30-09-2022",
                  month: "SEP",
                  date: "30",
                },
                {
                  data: "Sri Mahalakshmi Devi alankaram will be on 01-10-2022",
                  month: "OCT",
                  date: "01",
                },
                {
                  data: "Sri Saraswathi Devi (Moola Nakshatram) alankaram will be on 02-10-2022",
                  month: "OCT",
                  date: "02",
                },
              ].map((item, i) => {
                return (
                  <div key={`elementdate +${i}`} className="element-date">
                    <div>
                      {item?.month}
                      <br />
                      <span>{item?.date}</span>
                    </div>
                    <p style={{ color: "#171717" }}>{item?.data}</p>
                  </div>
                );
              })
            ) : (
              <p style={{ textAlign: "center", marginTop: "20px" }}>
                No {active}
              </p>
            )}
          </div>
        </div>
      </div>{" "}
    </>
  );
};
export default withRouter(TemplateFour);
