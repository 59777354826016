import Axios from "../Utils/sd-axios/Axios";

const Sd_Darshanam_service = {
  getAllOnline: async (id) => {
    try {
      const resp = await Axios.get("/darshanam/all/online", {
        headers: {
          "X-Temple-Code": id,
        },
      });
      return resp.data;
    } catch (error) {
      if (error?.response?.statusCode === 401) {
        throw new Error("Session Expired");
      } else if (
        error?.response?.statusCode !== 500 &&
        error?.response?.message
      ) {
        throw new Error(error?.response?.message);
      } else {
        //console.error(error);
        // throw new Error("something is wrong");
      }
    }
  },

  getAllvalidOnline: async (id) => {
    try {
      const resp = await Axios.get("/darshanam-online-booking/valid-products", {
        headers: {
          "X-Temple-Code": id,
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });
      return resp.data;
    } catch (error) {
      if (error?.response?.statusCode === 401) {
        throw new Error("Session Expired");
      } else if (
        error?.response?.statusCode !== 500 &&
        error?.response?.message
      ) {
        throw new Error(error?.response?.message);
      } else {
        //console.error(error);
        // throw new Error("something is wrong");
      }
    }
  },

  getDailyQuota: async (
    fromDate,
    toDate,
    // productSlotId,
    productId,
    onlyOnlineEnabled,
    id
  ) => {
    try {
      let query = `/darshanam-slot-daily-quota?fromDate=${fromDate}&toDate=${toDate}&productId=${productId}&onlyOnlineEnabled=${onlyOnlineEnabled}`;
      // if (productSlotId && productSlotId !== "") {
      //   query += `&productSlotId=${productSlotId}`;
      // }
      const resp = await Axios.get(query, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Code": id,
        },
      });
      return resp.data;
    } catch (error) {
      if (error?.response?.statusCode === 401) {
        throw new Error("Session Expired");
      } else if (
        error?.response?.statusCode !== 500 &&
        error?.response?.message
      ) {
        throw new Error(error?.response?.message);
      } else {
        //console.error(error);
        // throw new Error("something is wrong");
      }
    }
  },
};

export default Sd_Darshanam_service;
