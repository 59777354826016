import React, {useEffect, useContext} from "react";
import LoadingContent from "../../../Components/sd-common-components/sd-content-loading";
import "../../../Utils/sd-styles/sd-css/main.css";
import {SD_Context} from "../../../context/sd-context";

const Sd_dashboard_online_booking_kalayanamandapam = () => {
  const {setActive, setActivePath} = useContext(SD_Context);
  useEffect(() => {
    setActive("online-booking-kalaynamandapam");
    setActivePath("Onlinebooking-Kalayana Madapam");
  }, []);
  return (
    <div className="col-xs-12 col-sm-12 col-md-9 col-lg-9 sd-dashboard">
      <LoadingContent />
    </div>
  );
};
export default Sd_dashboard_online_booking_kalayanamandapam;
