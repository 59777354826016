const FooterData = (key) => {
  const about = [
    { name: "The Temple", path: "the-temple" },
    { name: "Temple Story", path: "temple-story" },
    { name: "General Information", path: "genereal-information" },
  ];

  const sevasAndDarshanam = [
    { name: "Darshanam", path: "darshanam" },
    { name: "Paroksha Seva", path: "paroksha-seva" },
    { name: "Pratyaksha Seva", path: "pratyaksha-seva" },
    { name: "Saswata Seva", path: "saswata-seva" },
  ];

  const donation = [
    { name: "e-Hundi", path: "e-hundi" },
    {
      name: "Devasthanam Trust ",
      path: "devasthanam-trust ",
    },
    {
      name: "Anna Prasadam Trust ",
      path: "anna-prasadam-trust ",
    },
    {
      name: "Go Samrakshana Trust ",
      path: "go-samrakshana-trust ",
    },
    {
      name: "Srikalahasthi TV ",
      path: "Srikalahasthi-tv ",
    },
    {
      name: "Srikalahasthi Trust",
      path: "Srikalahasthi-trust ",
    },
  ];

  const onlineBooking = [
    { name: "Pratyaksha Seva Tickets", path: "pratyaksha-seva" },
    { name: "Paroksha Seva Booking", path: "paroksha-seva" },
    { name: "Darshanam Tickets", path: "darshanam" },
    { name: "Prasadam", path: "Prasadam" },
    { name: "Kalyana Katta Tickets", path: "kalyanakatta" },
    { name: "Accommodation", path: "Accommodation" },
    { name: "Anna Prasadam Tickets", path: "anna-prasadam" },
    { name: "Kalyana Mandapam Booking", path: "kalyana-mandapam-booking" },
  ];

  const mediaRoom = [
    { name: "Media Kit", path: "media-kit" },
    { name: "Gallery", path: "gallery" },
    { name: "Whats New Gallery", path: "gallery" },
    { name: "Press", path: "press" },
    { name: "RTI Act", path: "rti-act" },
  ];
  const support = [
    { name: "FAQs", path: "faqs-all" },
    { name: "Facilities to Pilgrims", path: "facilities-to-pilgrims" },
    { name: "Connectivity", path: "connectivity" },
    { name: "Live Chat", path: "live-chat" },
    { name: "Contact Us", path: "contact-us" },
  ];

  const legalities = [
    { name: "Privacy Policy", path: "privacy-policy" },
    { name: "Refund Policy", path: "refund-policy" },
    { name: "Trademarks", path: "trademarks" },
    { name: "Terms and Conditions", path: "terms-and-conditions" },
    { name: "Cautionary Notice", path: "cautionary-notice" },
  ];

  const dataMap = {
    about: about,
    "seva-darshanam": sevasAndDarshanam,
    donation: donation,
    "online-data": onlineBooking,
    support: support,
    legalities: legalities,
    media: mediaRoom,
  };
  return dataMap[key];
};
export default FooterData;
