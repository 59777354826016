import React from "react";
import { withRouter } from "react-router-dom";
import { SD_Logo_circle } from "../../Assets/imageList";
import "../../Utils/sd-styles/sd-home.scss";
const Sd_comming_soon = ({ history }) => {
  return (
    <div className="sd-coming-soon">
      <img src={SD_Logo_circle} alt="logo" height={"100px"} />
      <h4> Coming Soon</h4>{" "}
      <p>
        Return to{" "}
        <span
          onClick={() => {
            history.push("/");
          }}
        >
          Home page
        </span>
      </p>
    </div>
  );
};
export default withRouter(Sd_comming_soon);
