import React, { useContext, useEffect, useState } from "react";
import SD_Pratyaksha_Seva_Inner_Component_One from "../../../Components/body/sd-pratyaksha-seva/sd-pratyaksha-seva-inner-components/sd-pratyaksha-seva-inner-component-one";
import Sd_gallery_semi_view from "../../../Components/sd-common-components/sd-gallery-semi-view";
import SD_Pratyaksha_Seva_One from "../../../Components/body/sd-pratyaksha-seva/sd-pratyaksha-seva-component-one";
import PratyakshaSevaService from "../../../services/sd-pratyakshaseva.service";
import { SD_Context } from "../../../context/sd-context";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Sd_faqs_semi_view from "../../../Components/sd-common-components/sd-faqs-semi-view";

import Sd_loading from "../../../Components/sd-common-components/sd-loading";
import { Helmet } from "react-helmet";
import SD_Pratyaksha_Seva_Inner_Component_Two from "../../../Components/body/sd-pratyaksha-seva/sd-pratyaksha-seva-inner-components/sd-pratyaksha-seva-inner-component-two";

const Sd_sevas_and_darshanam_pratyakshaseva_break_darsham = () => {
  //-------------------------------------------------------------states-----------------------------------------------------------//
  const { pratyakshaSevaData, setPratyakshaSevaData } = useContext(SD_Context);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  //-------------------------------------------------------------states-----------------------------------------------------------//

  //-------------------------------------------------------------useeffect-----------------------------------------------------------//
  useEffect(() => {
    if (pratyakshaSevaData.length !== 0) {
      setLoading(true);
      let datas = pratyakshaSevaData.find(
        (item) =>
          item?.name
            ?.replace?.(/\s/g, "")
            ?.replace?.(/[{()}]/g, "")
            .toLowerCase() === "vipbreakdarshanam"
      );
      if (datas) {
        setData(datas);
        setLoading(false);
      }
    } else {
      getData();
    }
  }, []);
  //-------------------------------------------------------------useeffect-----------------------------------------------------------//

  //-------------------------------------------------------------functions-----------------------------------------------------------//
  const getData = async () => {
    setLoading(true);
    try {
      let data = await PratyakshaSevaService.getAll();
      if (data) {
        setPratyakshaSevaData(data);
        let datas = data.find(
          (item) =>
            item?.name
              ?.replace?.(/\s/g, "")
              ?.replace?.(/[{()}]/g, "")
              .toLowerCase() === "vipbreakdarshanam"
        );
        if (datas) {
          setData(datas);
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };
  //-------------------------------------------------------------functions-----------------------------------------------------------//
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          VIP Break Darshanam Sri Veera Venkata Sathyanaraya Swamy Vari
          Devasthanam Online Booking | Prathyaksha Seva | Sri Durga Malleswara
          Swamy Varla Devasthanam Online
        </title>
        <meta
          name="description"
          content="VIP Break Darshanam is as dear to the goddess as Durga Sapthashathi. Participate in VIP Break Darshanam by purchasing Paroksha Seva tickets Online."
        />
        <meta
          name="keywords"
          content="VIP Break Darshanam Sri Kalahastheeswara Swamy Vari Devasthanam Online Booking, Prathyaksha Seva, Sri Kalahastheeswara Swamy Vari Devasthanam Online"
        />
      </Helmet>
      {loading ? (
        <Sd_loading />
      ) : (
        <div className="w-100">
          <SD_Pratyaksha_Seva_One from={"p-VIP Break Darshanam"} data={data} />
          <SD_Pratyaksha_Seva_Inner_Component_One data={data} />{" "}
          <SD_Pratyaksha_Seva_Inner_Component_Two data={data} />
          <Sd_faqs_semi_view from="pratyaksha_seva" />
          {/* <SD_Paroksha_Seva_Four /> */}
          <Sd_gallery_semi_view />
        </div>
      )}
    </>
  );
};
export default Sd_sevas_and_darshanam_pratyakshaseva_break_darsham;
