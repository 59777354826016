import React from "react";
import { withRouter } from "react-router-dom";
// import { SD_Logo_circle } from "../../Assets/imageList";
import "../../Utils/sd-styles/sd-home.scss";
const SD_dieties = ({ data }) => {
  return (
    <div className="">
      <p>{data}</p>
    </div>
  );
};
export default withRouter(SD_dieties);
